import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, ImageBackground, Pressable, Linking } from 'react-native';
import React, {useState, useEffect} from 'react';  

import { useRoute } from '@react-navigation/native';

import { Text, View } from "../components/Themed";
import prodFaqs from "../components/ProductFaqs";
import HamburgerWhite from "../components/HamburgerWhite";
import { MaterialIcons } from '@expo/vector-icons';
import { ResizeMode } from 'expo-av';

import {SafeAreaProvider, useSafeAreaInsets} from 'react-native-safe-area-context';

const baseUrl = 'https://2san.com/api/v1/GB';
import axios from 'axios';
let prodid=null;
let product=null;

let model = "";
let prod=null;

export default function ProductFaqsScreen (props) {  
	
	if (typeof props.route.params !== 'undefined') {
		if (typeof props.route.params.params.id !== 'undefined') {
			prodid = props.route.params.params.id;
		}
		else
		{		
		}
	}
	else
	{
	}

	const [stateProduct, setstateProduct] = useState(null);
	  useEffect(() => {
		getProduct();
	  });	

	const getProduct = () => {
		if (prodid !== null)
		{
			axios.get(`${baseUrl}/product/get/${prodid}?key=VjxFgtPrzxFT5Y3Odtp27XLazcA4jJGefY7vIXGQ38kw6lrYqt`)
				.then(res => {
					setstateProduct(JSON.stringify(res.data));
				})
		}
		else
		{
		}
	}

  const insets = useSafeAreaInsets();

	if (prodid === null)
	{
		React.useEffect(() => { props.navigation.navigate('productmenutext') }, []);
		return true;
	}
	else
	{
		product = JSON.parse(stateProduct);

		if (product != null)
		{
			if(product.hasOwnProperty('model'))
			{
				model = product.model;
				
				let name_en = product.name.en.replace(/(<([^>]+)>)/gi, "");
			
				let thispost=null;
				prodFaqs.map((post,i)=>{
					if (post.id == prodid)
					{
						thispost = post;
					}
				} 
				);
				
				return (

				// Passing configuration object to axios
				<ScrollView style={{height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%"}} scrollIndicatorInsets={{ right: 1 }}>	
					<View style={styles.container}>
							<View>
				        <Image style={{top: Platform.OS == "web" ? -70 : -30, position: 'absolute', width:"100%", height: 430}} source={thispost.productBackground} resizeMode="cover" />
				      </View>

				      <View style={[styles.headerwrapper, {paddingTop: insets.top, paddingLeft: insets.left, paddingRight: insets.right}]}>
      					<Image style={styles.headerlogo} source={require("../assets/images/2san-logo-white.png")} />
      					{Platform.OS === "web" ? (
      						<Pressable accessibilityLabel = "Link to product menu" onPress={() => props.navigation.navigate('productmenutext')}>
      							<HamburgerWhite />
          				</Pressable>
      					) : (
      						<Pressable accessibilityLabel = "Link to product menu"  onPress={() => {props.navigation.navigate('productmenutext');}}>
      							<HamburgerWhite />
          				</Pressable>
      					)}              		
      				</View>							
							
							<View style={styles.detailswrapper}>
								<Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginVertical: 20, flexDirection: "row" }}>
				            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: thispost.buttonColour, height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 5}]}>Back to product</Text>
				        </Pressable>
																
								<Text style={[styles.title, {color: thispost.buttonColour}]}>{ product.heading }</Text>
								<Text style={[styles.bodybold, {marginBottom: 0}]}>Frequently asked questions</Text>

								{thispost.question1 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question1 }</Text><Text style={styles.answertext}>{ thispost.answer1 }</Text></View>	: null}

								{thispost.question2 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question2 }</Text><Text style={styles.answertext}>{ thispost.answer2 }</Text></View>	: null}

								{thispost.question3 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question3 }</Text><Text style={styles.answertext}>{ thispost.answer3 }</Text></View>	: null} 

								{thispost.question4 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question4 }</Text><Text style={styles.answertext}>{ thispost.answer4 }</Text></View>	: null} 

								{thispost.question5 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question5 }</Text><Text style={styles.answertext}>{ thispost.answer5 }</Text></View>	: null}

								{thispost.question6 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question6 }</Text><Text style={styles.answertext}>{ thispost.answer6 }</Text></View>	: null}

								{thispost.question7 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question7 }</Text><Text style={styles.answertext}>{ thispost.answer7 }</Text></View>	: null}

								{thispost.question8 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question8 }</Text><Text style={styles.answertext}>{ thispost.answer8 }</Text></View>	: null}

								{thispost.question9 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question9 }</Text><Text style={styles.answertext}>{ thispost.answer9 }</Text></View>	: null}

								{thispost.question10 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question10 }</Text><Text style={styles.answertext}>{ thispost.answer10 }</Text></View>	: null}

								{thispost.question11 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question11 }</Text><Text style={styles.answertext}>{ thispost.answer11 }</Text></View>	: null}

								{thispost.question12 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question12 }</Text><Text style={styles.answertext}>{ thispost.answer12 }</Text></View>	: null}

								{thispost.question13 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question13 }</Text><Text style={styles.answertext}>{ thispost.answer13 }</Text></View>	: null}

								{thispost.question14 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question14 }</Text><Text style={styles.answertext}>{ thispost.answer14 }</Text></View>	: null}

								{thispost.question15 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question15 }</Text><Text style={styles.answertext}>{ thispost.answer15 }</Text></View>	: null}

								{thispost.question16 ? <View style={styles.questionwrapper}><Text style={[styles.questiontext, {color: thispost.buttonColour}]}>{ thispost.question16 }</Text><Text style={styles.answertext}>{ thispost.answer16 }</Text></View>	: null}

								<Pressable style={[styles.backbutton, {backgroundColor: thispost.buttonColour, marginBottom: 28, marginTop: 30}]} onPress={() => props.navigation.goBack()}>
          				<MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff",marginRight: 5}}/><Text style={styles.backbuttontext}>Back to product</Text>
          			</Pressable>
          			
          			<Text style={styles.body}>For more information on our products you can contact 2San at their address:</Text>
        
				        <Text style={styles.bodybold}>Unit 11 Carnforth Business Park,{"\n"}
				        Oakwood Way, Carnforth,{"\n"}
				        England, LA5 9FD</Text>				        

				        {Platform.OS === "web" ? (
				          <a href="mailto:helpdesk@2san.com" style={{backgroundColor: thispost.buttonColour, color:"#fff",marginBottom:15, height:44, display:"flex", textDecoration:"none", textAlign:"center", fontFamily: 'GalanoGrotesqueBold', fontSize:16, alignItems:"center", justifyContent:"center" }}>Email: helpdesk@2san.com<Image style={{marginLeft: 15, width: 21, height: 16 }} source={require("../assets/images/envelope-icon.png")} /></a>
				        ) : (
				          <Pressable style={[styles.feedbackbutton, {backgroundColor: thispost.buttonColour,}]} onPress={() => Linking.openURL("mailto:helpdesk@2san.com")}>
				            <Text style={styles.feedbackbuttontext}>Email: helpdesk@2san.com</Text><Image style={{width: 21, height: 16 }} source={require("../assets/images/envelope-icon.png")} />
				          </Pressable>
				        )}

				        <Text style={styles.body}>They also have a website at <Text style={styles.bodybold} onPress={() => Linking.openURL('https://www.2san.com')}>www.2san.com</Text></Text>
								
							</View>
						
					</View>
				</ScrollView>
			
				);
			}
		}
	}
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    marginBottom: 40,
    top: 0,
  },
  detailswrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
    paddingTop: 230,
  },
  headerwrapper: {
  	flex: 1,
    justifyContent: 'space-between',
    flexDirection: "row",
    backgroundColor: "transparent",
    alignItems: "center",
    marginTop: Platform.OS == "ios" ? 9 : 14,
    ...Platform.select({
      ios: {
        height: 74
      }
    }),
  },
  headerlogo: {
  	width: 150,
  	height: 36,
  	marginLeft: 21,
  },
  title: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
  },
  body: {
    fontSize: 13,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    lineHeight: 17,
    marginBottom: 12,
  },
  questionwrapper: {
  	marginTop: 30,
  },
  questiontext: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    lineHeight: 17,
    paddingBottom: 10,
  },
  answertext: {
    fontSize: 13,
    fontFamily: 'GalanoGrotesqueRegular',
  },
  heading: {
    fontSize: 15,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
    width: "100%",
  },
  imagewrapper: {
  	flex: 1,
  	marginTop: 30,
  	alignItems: "center",
  	backgroundColor: "transparent",
  	marginBottom: 5,
  },
  productimage: {
    height: 320,
    width: 200,
  },
  backbutton: {
  	alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 11,
    paddingHorizontal: 50,
    marginBottom: 15,
    width: "100%",
    flexDirection: "row",
  },
  backbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
  },
  feedbackbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 11,
    paddingHorizontal: 5,
    marginBottom: 15,
    flexDirection: "row",
  },
  feedbackbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
    marginRight: 15,
  },
});
