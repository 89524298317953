export default [
    {
        id:1,
        name:"Health & Wellbeing",
        menuBackgroundColour:"#E5F2EB",
        gradientBackgroundColour1:"#01833F",
        gradientBackgroundColour2:"#93B961",
    },
    {
        id:2,
        name:"Drugs of Abuse",
        menuBackgroundColour:"#E5F0F5",
        gradientBackgroundColour1:"#006CA1",
        gradientBackgroundColour2:"#85B3D9",
    },
    {
        id:3,
        name:"Fertility",
        menuBackgroundColour:"#EAE5F0",
        gradientBackgroundColour1:"#300370",
        gradientBackgroundColour2:"#B8227B",
    },
    {
        id:4,
        name:"Infection",
        menuBackgroundColour:"#FCF1EA",
        gradientBackgroundColour1:"#E27A34",
        gradientBackgroundColour2:"#DE3955",
    },
];
