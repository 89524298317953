export default [
    {
        id:23,
		categoryid:1,
        name:"Iron Deficiency",
        image:require("../assets/images/products/iron_deficiency/test-kits_0007_iron-deficiency.png"),
        productBackground:require("../assets/images/products/iron_deficiency/green_product_background.png"),
        buttonColour:"#3B8046",
        question1: "What is the Ferritin Rapid Test?",
        answer1: "The Ferritin Rapid Test is a fast and simple test for detecting ferritin levels in human fingerstick blood. Ferritin is a protein that stores iron inside your cells, and its level can help indicate whether you might have iron deficiency anemia. The test uses a lateral flow immunoassay to detect ferritin in your blood. It is semi-quantitative meaning it does not give you a quantitative result but will indicate positive or negative above or below a certain threshold.",
        question2: "How does the Ferritin Rapid Test work?",
        answer2: "If your ferritin level is above 30 ng/mL, a coloured line appears in the test region. If the ferritin concentration is below 30 ng/mL, this line doesn't appear. A coloured line will always appear in the control region, indicating that the test has been performed correctly.",
        question3: "How do I interpret the results of the test?",
        answer3: "If two coloured lines appear, this means the ferritin concentration in your blood is over 30ng/mL which means you are unlikely to have iron deficiency anemia. If only one coloured line appears (in the control region), the ferritin concentration in your blood is below 30ng/mL, indicating a possible iron deficiency. If no line appears in the control region, the test is invalid and needs to be repeated.",
        question4: "Is the test appropriate for everyone?",
        answer4: "This test is not suitable for identifying iron deficiency in pregnant women or individuals with chronic inflammatory diseases, in whom ferritin levels can vary. If you are over 60 with symptoms of iron deficiency it may be better to go directly to a doctor.",
        question5: "Are there any precautions I should take when using the Ferritin Rapid Test?",
        answer5: "Yes, you should read all instructions before using the test. The test should not be used if the packaging is damaged, and it should not be used after its expiration date. The test must be stored at room temperature or refrigerated (between 2-30 °C), and you should not eat, drink, or smoke in the area where the test is being conducted.",
        question6: "What should I do if the result is abnormal?",
        answer6: "If the result is abnormal, indicating a ferritin level below 30 ng/mL, you should consult a physician to identify the underlying cause. An iron deficiency can be a sign of other conditions such as celiac disease.",
        question7: "Can this test give inaccurate results?",
        answer7: "The results are accurate if the instructions are followed carefully. However, incorrect procedures, such as insufficient blood volume or the test getting wet before use, can lead to erroneous results. As with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
        question8: "What should I do if the result is normal but I still have symptoms?",
        answer8: "If the result is normal, but symptoms like paleness, feeling tired, headaches, faster heartbeat, or shortness of breath persist, it is recommended to consult a physician.",
        question9: "Can the Ferritin Rapid Test be used to diagnose other conditions?",
        answer9: "This test is intended to identify low levels of ferritin only. Low ferritin levels may indicate other conditions such as celiac disease or cancer. However, for a confirmed diagnosis, you should consult with a healthcare professional.",
    },
    {
        id:24,
		categoryid:1,
        name:"Vitamin D Deficiency",
        image:require("../assets/images/products/vitamin_d_deficiency/test-kits_0004_vitamin-d-deficiency.png"),
        productBackground:require("../assets/images/products/vitamin_d_deficiency/green_product_background.png"),
        buttonColour:"#3B8046",
        question1: "What is the Vitamin D Rapid Test used for?",
        answer1: "The Vitamin D Rapid Test is a test used to detect the level of 25-hydroxyvitamin D (25 (OH) D) in human fingerstick whole blood. It can help screen for Vitamin D deficiency.",
        question2: "How does Vitamin D deficiency affect the body?",
        answer2: "Vitamin D deficiency can affect the immune system and make it more likely that you contract common infections. Long term, there is some evidence linking Vitamin D deficiency to diseases such as osteoporosis, multiple sclerosis and cardiovascular diseases.",
        question3: "How should I perform the test?",
        answer3: "Wash your hands, open the package, and place the test cassette on a clean surface. Use the lancet to puncture your finger and collect blood with the capillary dropper. Add the blood to the specimen well and wait for the coloured lines to appear. Compare the test line intensity with the provided colour card.",
        question4: "How should I interpret the test results?",
        answer4: "A deficient result means the Vitamin D level is less than 10ng/mL, an insufficient result means it is between 10ng/mL and 30ng/mL, and a sufficient result means it is equal to or higher than 30ng/mL. Refer to the colour card for comparison. If your results indicate you are Vitamin D deficient or insufficient, it is advisable to consult a healthcare professional for definitive testing and to understand the underlying cause.",
        question5: "Can the test results be incorrect?",
        answer5: "The test is most accurate if the instructions are followed correctly, however no test is 100% accurate. Errors can also occur if the test cassette gets wet, insufficient blood is dispensed, or incorrect procedure is followed. As with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
        question6: "When should I read the test results?",
        answer6: "The results should be read at 10 minutes after adding the buffer. Reading the results after 20 minutes may lead to unreliable results.",
        question7: "What should I do if the result is deficient or insufficient?",
        answer7: "If the result indicates Vitamin D deficiency or insufficiency, consult a pharmacist or a doctor and show them the test result. The physician will determine if further analysis or supplementation is needed.",
        question8: "What should I do if the result is sufficient?",
        answer8: "If the result is sufficient, it means the Vitamin D level is within the normal range. However, if symptoms persist, consult a physician for further advice.",
        question9: "How should I store the test kit?",
        answer9: "Store the kit in a dry place at temperatures between 2-30°C (36-86°F). Do not use the kit if the package is damaged. Keep it out of the reach of children.",
        question10: "Can I reuse the test kit?",
        answer10: "No, the test kit is designed for single use only. Do not dismantle or touch the test window of the test cassette.",
    },
    {
        id:21,
		categoryid:1,
        name:"Hypothyroidism (Underactive Thyroid)",
        image:require("../assets/images/products/hypothyroidism/test-kits_0008_hypothyroidism.png"),
        productBackground:require("../assets/images/products/hypothyroidism/green_product_background.png"),
        buttonColour:"#3B8046",
        question1: "How does the TSH test work?",
        answer1: "The TSH Rapid Test detects levels of Thyroid Stimulating Hormone (TSH) in human fingerstick whole blood.", 
        question2: "What is Thyroid Stimulating Hormone?",
        answer2: "TSH is released by the pituitary gland and activates the thyroid gland, telling it how much thyroid hormone to release. Thyroid hormone in turn acts on the pituitary gland to tell it to stop making TSH. If your thyroid gland is failing to make sufficient thyroid hormone (hypothyroidism), the pituitary releases more TSH to try to activate your thyroid gland.\n\nTSH level over 5uIU/mL in case of a positive result, indicates high levels of TSH in the blood.", 
        question3: "When should the test be used?", 
        answer3: "This test can be used if you are experiencing the following symptoms and think you may have an underactive thyroid: regularly feeling tired, depressed, or cold, weight gain, dry skin, brittle hair, enduring constipation or irregular periods if you are female. It is recommended to perform a TSH Rapid Test for screening purposes: you will need a consultation with a doctor and additional tests for onward treatment.", 
        question4: "Are there any precautions?", 
        answer4: "The TSH Rapid Test can be used any time of the day. Do not use this if you are unwell as an acute illness may affect the test results, and you must not use the test while undergoing thyroid treatment. This test is not suitable if you are pregnant, have recently given birth, or are currently breastfeeding, as your TSH levels will be affected.", 
        question5: "Can the result be incorrect?",
        answer5: "The cut-off range of 5uIU/mL for this test is on the higher side: some regions set a cut-off of 3uIU/mL, meaning that after a negative result with this Rapid Test you might get a positive result using a different test.\n\nThe results are most accurate when the instructions are followed correctly, but no test is 100% accurate. Result can be incorrect if the cassette gets wet, if the quantity of blood dispensed into the sample well is insufficient, or if the number of buffer drops are less than 2 or more than 3. The capillary dropper provided in the box aids in dispensing the correct amount of collected blood.\n\nAs with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.", 
        question6: "How to interpret the test if the colour or the intensity of the lines are different?", 
        answer6: "The test should be considered positive if both lines appear and are clear and visible. The colour our intensity of the lines are not relevant to result interpretation.", 
        question7: "If I read the result after 20 minutes, will the result be reliable?", 
        answer7: "No. The result should be read at 10 minutes after adding the buffer. The result is unreliable after 20 minutes.", 
        question8: "What do I have to do if the result is positive?", 
        answer8: "If the result is positive, your TSH level in your blood is higher than the normal (5 IU/mL). You should consult a pharmacist or a doctor and show them test result for additional testing including T3 and T4.", 
        question9: "What do I have to do if the result is negative?", 
        answer9: "If the result is negative, it means that the TSH level is below 5uIU/mL. Additional testing of hormones such as T3 and T4 and a physician review is needed to exclude a diagnosis of primary hypothyroidism. In other conditions such as central hypothyroidism TSH levels may be normal. In addition, hyperthyroidism, though rare, but cannot be excluded based of this test.",
    },
    {
        id:19,
		categoryid:1,
        name:"Bowel Cancer",
        image:require("../assets/images/products/bowel_cancer/test-kits_0011_bowel-cancer.png"),
        productBackground:require("../assets/images/products/bowel_cancer/green_product_background.png"),
        buttonColour:"#3B8046",
        question1: "What is the FOB Rapid Test used for?",
        answer1: "The FOB Rapid Test is a rapid one-step fecal immunochemical test used for the qualitative detection of small amounts of hidden or \"occult\" blood in stool. Blood in the stool can be an early sign of serious conditions like colon cancer, ulcers, polyps, colitis or diverticulitis which may not show any visible symptoms in the early stages.",
        question2: "Why would I need to detect occult blood in my stool?",
        answer2: "This test is recommended if you have symptoms such as a recent unexplained change in bowel habit, like softer stools or going more or less frequently than usual, tummy pain, bloating, losing weight without trying, or feeling very tired. These symptoms, combined with occult blood in stool can be a sign of diseases such as colon cancer, which can be more easily treated if identified early on.\n\nIf you your stool is black or dark red, or you have bloody diarrhoea, do not use this test and contact a doctor urgently.",
        question3: "How accurate is the FOB Rapid Test?",
        answer3: "Clinical evaluations have shown the FOB Rapid Test to have a 99.1% overall accuracy when compared to another commercially available FOB rapid test. As with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
        question4: "Are there any dietary restrictions before using the FOB Rapid Test?",
        answer4: "No, there are no dietary restrictions necessary before using the FOB Rapid Test.",
        question5: "How do I collect and prepare a specimen for the test?",
        answer5: "Fecal specimens should be collected in the stool catcher provided to avoid contamination. Once collected, the specimen should be mixed with the extraction buffer in the specimen collection tube. The specimen is then added to the test cassette.",
        question6: "How do I interpret the results of the FOB Rapid Test?",
        answer6: "A positive result is indicated by two coloured lines appearing on the test cassette. The intensity of the colour in the test line region will vary depending on the concentration of blood present in the specimen. A negative result shows one coloured line in the control line region. An invalid result occurs if the control line fails to appear.",
        question7: "What should I do if the result is positive?",
        answer7: "If the result is positive, you should visit your doctor for an examination and further tests.",
        question8: "Does a positive result mean I have colon cancer?",
        answer8: "Not necessarily. The FOB Rapid Test only indicates the presence of occult blood. The presence of blood in stool does not necessarily indicate colon cancer. All results must be considered with other clinical information in consultation with a physician",
        question9: "How should the test cassette be stored?",
        answer9: "The kit can be stored at room temperature or refrigerated (2-30 °C). The test cassette must remain in the sealed pouch until use, and it should not be used beyond the expiration date.",
        question10: "Are there any precautions I should take while using the kit?",
        answer10: "Yes, the test should no be should not be used after its expiration date. Avoid eating, drinking, or smoking in the area where the specimens or kits are handled. The used test should be discarded according to local regulations. ",
        question11: "What if I get an invalid result?",
        answer11: "If the control line fails to appear, it means the test is invalid. This could be due to insufficient specimen volume or incorrect procedural techniques. You should review the procedure and repeat the test with a new kit. If the problem persists, stop using the test kit and contact your local distributor.",
    },
    {
        id:20,
        name:"Stomach Ulcer",
		categoryid:1,
        image:require("../assets/images/products/stomach_ulcer/test-kits_0006_stomach-ulcer-h-pylori.png"),
        productBackground:require("../assets/images/products/stomach_ulcer/green_product_background.png"),
        buttonColour:"#3B8046",
        question1: "What is the H.pylori Test used for?",
        answer1: "H.pylori is a common infection, estimated to affect 50% of the world’s population. It is a major cause of stomach ulcers and small intestine (duodenal) ulcers. H.pylori can be easily treated so it is important to test for it if you have symptoms of an ulcer, such as belching and pain before or after eating.", 
        question2: "How does the H.pylori Test work?",
        answer2: "H.pylori is a small, spiral-shaped bacterium that lives in the surface of the stomach and duodenum. The H.pylori Antigen Rapid Test detects H.pylori antigens in your stool to determine the presence of the bacterium.",
        question3: "Can this test diagnose a stomach ulcer?",
        answer3: "No. This test detects the presence of H.pylori antigens. H.pylori may or may not be causing a stomach ulcer which can only be diagnosed for certain by a physician after an endoscopy. Also, symptoms of stomach or duodenal ulcers may be confused with symptoms caused by other diseases so it is important to consult a physician.",
        question4: "Can the result be incorrect?",
        answer4: "The results are accurate when the instructions are carefully respected. Results can be incorrect if the cassette gets wet before performing the test or if the quantity of stool dispensed in the sample well is too much or insufficient, or if the number of extracted specimen drops are less than 2 or more than 3.\n\nIn addition, example, the test could be falsely negative if taken shortly after treatment with antibiotics, bismuth-containing medications, or proton pump inhibitors (PPIs).\n\nAs with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
        question5: "What do have to do if the result is positive?",
        answer5: "If the result is positive, it means that the H.pylori antigens were detected in your stool and you should consult a healthcare professional who will determine whether additional analysis should be performed. Your physician may perform other tests such as an H.pylori breath test, blood tests or an endoscopy.",
        question6: "What do I have to do if the result is negative?",
        answer6: "If the result is negative, it means that it was not possible to detect H.pylori antigens. If symptoms persist, consult a pharmacist or physician.",
        question7: "What should I do if the result is negative but I still have symptoms?",
        answer7: "If the test result is negative and symptoms persist, additional testing using other clinical methods is recommended. A negative result does not at any time preclude the possibility of H.pylori infection.",
        question8: "How should I interpret the test if the colour and the intensity of the lines are different?",
        answer8: "The colour and intensity of the lines have no importance for result interpretation. The lines should only be homogeneous and clearly visible. The test should be considered as positive whatever the colour or intensity of the test line.",
        question9: "Can the H.pylori Rapid Test be used to diagnose other conditions?",
        answer9: "No, the H.pylori Rapid Test will only indicate the presence of H.pylori antigens in the specimen and should not be used as the sole criteria for diagnosing a stomach or duodenal ulcer.",
    },
    {
        id:279,
        name:"Menopause (FSH)",
        categoryid:1,
        image:require("../assets/images/products/menopause/test-kits_menopause.png"),
        productBackground:require("../assets/images/products/menopause/green_product_background.png"),
        buttonColour:"#3B8046",
        question1: "How does the FSH Rapid Test work?",
        answer1: "The FSH Rapid Test detects the presence of Follicle-Stimulating Hormone (FSH) in urine samples. FSH levels increase as a woman's body produces less estrogen (which normally inhibits FSH), indicating the perimenopause stage. The test uses antibodies to selectively detect elevated levels of FSH in the urine. The test does not diagnose the Menopause, but can be helpful to understand how your hormones might be changing.",
        question2: "When is the best time to perform the test?",
        answer2: "For accurate results, it is recommended to use the test with the first morning urine, as it contains the highest concentration of hormones. If you are still menstruating, start testing during the first week of your cycle (days 2-7, with day 1 being the first day of menstruation). If you no longer have regular periods, you can take the test at any time during the month.",
        question3: "How can I be sure that the test has worked properly?",
        answer3: "To ensure accurate results it is important to follow the Instructions For Use, correctly and take care to hold the absorbent tip in your urine for 10-15 seconds, only reading the test at 3 minutes. Check for a colored line in the Control line region (C). If a line appears, it means you collected the correct sample and the test worked correctly. If no line appears in the Control region, review the instructions to check you are performing it correctly, and repeat the test using a new midstream test. The test is not reusable.",
        question4: "Can I stop using contraception if I receive a positive result?",
        answer4: "No, the FSH Rapid Test cannot determine fertility. Even with a positive result indicating elevated FSH levels, it is essential to consult with your doctor to confirm your menopause status before discontinuing contraception. Elevated FSH does not necessarily mean you are in the menopause.",
        question5: "How accurate is the FSH Rapid Test?",
        answer5: "In a clinical evaluation comparing this test to another commercially available urine FSH test, this test showed 100% concordance, identifying the same positive and negative results in a sample of 250 urine specimens.",
        question6: "Do alcohol or common medications affect the test?",
        answer6: "No, alcohol and common medications do not affect the test. However, if you are taking hormonal medication, it is advisable to consult your physician. Certain factors like recent oral contraceptive use, breastfeeding, pregnancy, or hormonal imbalances can potentially influence the test results.",
        question7: "Are there any limitations to the test?",
        answer7: "False positive or false negative results are possible with this test. It is important to consult your physician before making any decisions based on the results of this test. It is important to note that a single elevated FSH test does not diagnose the menopause, and FSH levels vary from cycle to cycle. Invalid results are often caused by not following the instructions properly. If problems persist, discontinue using the test kit and contact your local distributor.",
        question8: "How does follicle-stimulating hormone (FSH) relate to menopause?",
        answer8: "Consistently elevated FSH levels, in combination with age, symptoms and irregular/lack of menstruation can indicate menopause. A single FSH level can be misleading during perimenopause due to fluctuating estrogen levels. FSH levels change between cycles and within cycles so it is recommended to test over a few cycles and if you are unsure, consult your doctor. For women over 45, a diagnosis of the menopause is usually made on the basis of symptoms alone, and this is generally sufficient.",
        question9: "How effective and accurate is FSH as an indicator of the menopause?",
        answer9: "For women over the age of 45, testing may not provide significant additional benefits. FSH levels can be an unreliable indicator of menopause, and it is not the sole determining factor. It is recommended to avoid testing if experiencing symptoms and over 45. If testing is desired, conducting tests over multiple cycles to observe sustained and consistent FSH rise is advised.",  
    },
    {
        id:22,
		categoryid:2,
        name:"Drugs of Abuse",
        image:require("../assets/images/products/drugs_of_abuse/test-kits_0009_drug-of-abuse.png"),
        productBackground:require("../assets/images/products/drugs_of_abuse/blue_product_background.png"),
        buttonColour:"#2F6B9D",
        question1: "What is the Multi Drug Test used for?",
        answer1: "The Multi Drug Test is a one-step screening test that can detect the presence or absence of multiple drugs and their metabolites in human urine. It can screen for drugs such as cocaine, amphetamine, methamphetamine, cannabis, opiates, and benzodiazepines.",
        question2: "How does the Multi Drug Test work?",
        answer2: "The test involves immersing the test panel into a urine sample for 10 seconds. Afterward, the test panel is placed on a flat surface and the results are read after 5 minutes. The presence or absence of coloured lines in specific windows indicates the presence or absence of drugs.",
        question3: "Can the Multi Drug Test measure the level of intoxication or how drugs were administered?",
        answer3: "No, the test can only indicate the presence or absence of drugs in the urine sample. It does not provide information about the level of intoxication or the method of drug administration.",
        question4: "Can the Multi Drug Test distinguish between drugs of abuse and medications?",
        answer4: "No, the test cannot differentiate between drugs of abuse and certain medications. It is important to consult a healthcare professional for a more accurate interpretation of the results.",
        question5: "Are the test results always accurate?",
        answer5: "While the test is designed to provide accurate results, technical or procedural errors, as well as certain substances in the urine sample, may lead to incorrect results. If there is a suspicion of contamination or if the result is unexpected, it is advisable to conduct a new test with a different urine sample. As with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
        question6: "What should I do if the result is positive for any of the tested drugs?",
        answer6: "A positive result indicates the presence of a specific drug or its metabolites. It is important to contact a doctor for advice, consultation, and possibly re-testing. It is not recommended to make any medical decisions based solely on this test result.",
        question7: "Can the Multi Drug Test determine the concentration of drugs in urine?",
        answer7: "No, the test cannot provide quantitative information about the concentration of drugs in the urine sample. For more specific and detailed information, a laboratory-based method should be used.",
        question8: "How should I store the Multi Drug Test?",
        answer8: "The test should be stored at temperatures between 4°C and 30°C. It should be kept away from direct sunlight, moisture, and heat. Ensure it is stored out of the reach of children.",
        question9: "Can the test be reused?",
        answer9: "No, the test is for single-use only. It should not be taken apart or ingested in any way.",
        question10: "What should I do if the test result is invalid?",
        answer10: "An invalid result occurs when no control line is visible. This could be due to insufficient urine sample volume or incorrect procedural technique. If an invalid result occurs, it is recommended to repeat the test with a new urine sample and follow the instructions carefully.",
    },
    {
        id:28,
		categoryid:3,
        name:"Male Fertility",
        image:require("../assets/images/products/male_fertility/test-kits_0003_male-fertility.png"),
        productBackground:require("../assets/images/products/male_fertility/purple_product_background.png"),
        buttonColour:"#300470",
        question1: "What is the Sperm Concentration Rapid Test?",
        answer1: "The Sperm Concentration Rapid Test is a quick and simple test for detecting sperm concentration levels to help you plan a successful pregnancy. It is semi-quantitative meaning it does not give you a quantitative result but will indicate positive or negative above or below a certain threshold.",
        question2: "What is the intended use of the Sperm Concentration Rapid Test?",
        answer2: "The test is designed for self-testing. It aims to identify sperm concentration in human semen as first step in understanding your fertility and to aid pregnancy planning. It is important to note that there are other important tests you may need to do to get the full picture of your fertility.",
        question3: "How does the Sperm Concentration Rapid Test work?",
        answer3: "This product uses a membrane with small pores to filter the semen. Sperm cells are trapped on the surface of the first layer of the membrane, and staining solution is used that can dye sperm cells.",
        question4: "How do I interpret the results of the test?",
        answer4: "The darker the colour of well \"A\", the higher the sperm concentration. If the colour of test well \"A\" is lighter than the standard colour of reference well \"B\", it means that the concentration of sperm is less than 15 million/mL.\n\nIf the colour of test well \"A\" is darker than the standard colour of reference well \"B\", it means that the sperm concentration is greater than 15 million/mL.\n\nSperm concentration of 15 million/mL is the minimum expected sperm concentration level for pregnancy. This is, however, a rough guideline and conception can still occur with lower sperm counts. Importantly, there are other factors that are important in achieving successful pregnancy such as sperm motility and shape (morphology) that are not identified by this test.",
        question5: "Are there any precautions I should take when using the Sperm Concentration Rapid Test?",
        answer5: "Yes, for best results, abstain from sexual intercourse for between 2 and 7 days prior to taking the test. The kit is designed for use with human semen only and should not be used with other body fluids. Store the kit at room temperature in a dry area, and do not use if the foil packaging is damaged or opened. Once the test cassette package is opened, use it promptly to prevent exposure to air that could affect the test results. When adding specimens and solutions, avoid bubbles as they can impact the accuracy of the test. Follow the provided instructions carefully regarding timing and result interpretation. Do not freeze the kit or use it beyond the expiration date. Do not remove the light-yellow powder from the sample cup as it assists in semen liquefaction. Keep the kit out of reach of children.",
        question6: "What should I do if the result is abnormal?",
        answer6: "Sperm concentration is one of several semen analysis tests. There are other factors that should be considered, including motility. Therefore, it is strongly recommended that you seek expert medical advice if you get an abnormal result.",
        question7: "Can this test give inaccurate results?",
        answer7: "Any mistake at any point of time from sample collection to test timing to non-compliance to abstinence may result in erroneous test results. As with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
        question8: "What should I do if the result is normal but I still have symptoms?",
        answer8: "If the result is normal, but you are still having problems conceiving, it is recommended to consult a physician together with your partner.",
        question9: "Can the Sperm Concentration Rapid Test be used to diagnose other conditions?",
        answer9: "No, but if you are unsure of the result or you feel the result is inaccurate, repeat the test, being sure to avoid sexual intercourse for 2 to 7 days prior to taking the test. If the second test is still abnormal, you should discuss the results with your doctor or medical professional.",
    },
    {
        id:29,
		categoryid:3,
        name:"Peak Fertility",
        image:require("../assets/images/products/peak_fertility/test-kits_0001_peak-fertility.png"),
        productBackground:require("../assets/images/products/peak_fertility/purple_product_background.png"),
        buttonColour:"#300470",
        question1: "When can I do the test?",
        answer1: "After you determine the best day to start testing based on your monthly cycle, you can do the test any time on that day. Testing with first morning urine is not recommended because it is concentrated and may give a false positive result. Testing between 10:00 AM and 8:00 PM is a good time frame, with many researchers suggesting testing at 12:00 AM. Test at about the same time each day. Reduce your liquid intake for 2 hours before testing.",
        question2: "Can I use the LH Ovulation Rapid Test to avoid pregnancy?",
        answer2: "No, the test should not be used as a form of birth control.",
        question3: "How accurate is the LH Ovulation Rapid Test?",
        answer3: "A clinical evaluation was conducted comparing the results obtained using the LH Ovulation Rapid Test to another commercially available urine LH test. The in-house clinical trial included 300 urine specimens. The results demonstrated >99.9% specificity and >99.9% sensitivity with an overall accuracy of >99.9%.", 
        question4: "What if no line appears in the Control region?",
        answer4: "If no line appears in the Control region after 3 minutes, the result is invalid and you should repeat the test with a new test. If the problem persists, please contact the manufacturer.", 
        question5: "I have tested for several days and I did not see a surge. What do I do?", 
        answer5: "Since every woman does not always ovulate at mid-cycle, you may not detect the hLH surge in the first seven days of testing. This could mean you have not ovulated yet and you should continue testing with an additional LH Test.",
        question6: "I have tested for 8 days or more and I still did not see my hLH surge, what is wrong?",
        answer6: "About 90% of ovulating women with regular cycles will see their surge during 8–10 days of about 90% of ovulating women with regular cycles will see their surge during 8–10 days of testing. If you do not, it could mean that you have not ovulated this month. If you are having a short cycle this month, it is possible that you have ovulated before you started testing. If you are having a long cycle this month, you may not have ovulated yet. You may continue testing or test again next month. Don't worry; it is normal to have an occasional short or long cycle, or to have a cycle without ovulating.",
        question7: "I have used the ovulation test for three months, and each month I have seen a surge and have had intercourse that day or night. I have not become pregnant yet. What is wrong?",
        answer7: "First, it is important to remember that it can take normal, healthy couples many months to become pregnant. There are many factors that can affect your ability to become pregnant even if you have been able to have intercourse during your most fertile days. If after several months you have no success, consult with your physician or health care provider.",
        question8: "What could interfere with my test result?", 
        answer8: "if you are already pregnant, have recently been pregnant, or have reached menopause you may get a misleading result. Some prescription drugs, such as menotropins for injection and Danazol may affect the results you see. Please consult your physician if you have any questions about prescription drug interactions.", 
        question9: "Will oral contraceptives affect the result?",
        answer9: "After using the pill your cycle may be irregular and may take some time to stabilize again. You may wish to wait until you have had two normal periods before starting to use the Test.", 
        question10: "If the Test can determine my most fertile time, why can't I use it for contraception?",
        answer10: "Sperm can survive up to 72 hours, and this test predicts your hLH surge only up to 24 to 36 hours in advance. Therefore, if you have intercourse before you discover your surge, the egg can still be fertilized.",
        question11: "How sensitive is the LH Ovulation Rapid Test?",
        answer11: "The LH Ovulation Rapid Test detects human luteinizing hormone (hLH) in urine at concentrations of 40 mIU/mL or higher. The addition of FSH (1,000 mIU/mL), hCG (10,000 mIU/mL), and TSH (1,000 μIU/mL) to negative (0 mIU/mL hLH) and positive (40 mIU/mL hLH) specimens showed no cross-reactivity.", 
        question12: "Do alcohol or common medications affect the test?",
        answer12: "No, but you should consult your physician if you are taking any hormonal medication. Also, recent oral contraceptive use, breastfeeding, or pregnancy could affect the test results.", 
        question13: "How long will the lines remain visible?",
        answer13: "The test should be read at 3 minutes for best results. A positive (Surge) result will never disappear. The coloured line(s) may become darker and a tinted background may appear after several hours. Some negative results may later display a faint second coloured line because of evaporation from the test line. Therefore, you should not read the results after 10 minutes; discard the test once you have read the result.", 
        question14: "I am now using the basal body temperature method (BBT). Does this test replace BBT?", 
        answer14: "The LH Ovulation Rapid Test does not replace the BBT method. The shift in basal body temperature primarily indicates that ovulation has already occurred. The LH Ovulation Rapid Test indicates that ovulation is about to occur.",
        question15: "I received a positive result and had intercourse during these fertile days but I have not become pregnant. What shall I do?",
        answer15: "There are many factors that can affect your ability to become pregnant. It can take normal, healthy couples many months to achieve a pregnancy and often you may need to use the test kit for 3-4 months before achieving pregnancy. If pregnancy is not achieved after 3-4 months, you and your partner should consult your physician.",
        question16: "I have had a positive result and had intercourse during these fertile days. I think I may be pregnant. How soon can I find out?",
        answer16: "The hCG Pregnancy Rapid Test can provide you with results as early as the first day of your missed period.",
    },
    {
        id:30,
		categoryid:3,
        name:"Early Pregnancy",
        image:require("../assets/images/products/early_pregnancy/test-kits_0002_early-pregnancy.png"),
        productBackground:require("../assets/images/products/early_pregnancy/purple_product_background.png"),
        buttonColour:"#300470",
        question1: "How does the 2San Early Pregnancy Test work?",
        answer1: "The 2San Early Pregnancy Test detects a hormone in your urine that your body produces during pregnancy (human chorionic gonadotropin, or HCG). The amount of this pregnancy hormone increases as pregnancy progresses.",
        question2: "How soon after I suspect that I am pregnant can I take the test?",
        answer2: "6 days before your missed period and 5 days before the expected date of your next period.",
        question3: "Do I have to test with first-morning urine?",
        answer3: "Although you can test at any time of the day, your first-morning urine is usually the most concentrated of the day and would have the most hG in it.",
        question4: "How accurate is the test?",
        answer4: "A clinical evaluation was conducted comparing the results obtained using the hG Pregnancy Enhanced Sensitivity Rapid Test Midstream to another commercially available urine hCG test. The consumer clinical trial included 608 urine specimens: both assays identified 231 positive and 377 negative results. The results demonstrated >99% overall accuracy of the hOG Pregnancy Enhanced Sensitivity Rapid Test Midstream when compared to the other urine hCG test.",
        question5: "How sensitive is the test?",
        answer5: "HG Pregnancy Enhanced Sensitivity Rapid Test Midstream detects CG in urine at a concentration of 10 mIU/mL or greater. The test has been standardised to the W.H.O. International Standard. The addition of LH (300 mIU/mL), FSH (1,000 mIU/mL), and TSH (1,000 MIU/mL) to negative (0 mIU/mL hCG) and positive (10 mIU/mL hCG) specimens showed no cross-reactivity.",
        question6: "What should I do if the result shows that I am pregnant?",
        answer6: "It means that your urine contains hCG and you are probably pregnant. See your doctor to confirm that you are pregnant and to discuss the steps you should take.",
        question7: "How do I know that the test was run properly?", 
        answer7: "The appearance of a coloured line in the control line region (C) tells you that you followed the test procedure properly and the proper amount of urine was absorbed.",
        question8: "What should I do if the result shows that I am not pregnant?",
        answer8: "It means that no CG has been detected in your urine and probably you are not pregnant. If you do not start your period within a week of its due date, repeat the of test with a new test. If you receive the same result after repeating the test and you still do not get your period, you should see your doctor.",
    },
    {
        id:25,
		categoryid:4,
        name:"Urinary Tract Infection (UTI)",
        image:require("../assets/images/products/urinary_tract_infection/test-kits_0005_urinary-tract-infection-uti.png"),
        productBackground:require("../assets/images/products/urinary_tract_infection/orange_product_background.png"),
        buttonColour:"#DE5929",
        question1: "What is the Urinary Tract Infections Test used for?",
        answer1: "The Urinary Tract Infections (UTI) Test detects the presence of certain analytes in urine, including leukocytes, blood, nitrite, and protein. In a person with symptoms of a UTI, the presence of leukocytes and nitrites in the urine indicates a urinary tract infection may be likely.",
        question2: "What are the symptoms of a urinary tract infection?",
        answer2: "Symptoms can vary but may include a burning sensation during urination, a strong urge to urinate, cloudy urine, or a strong odor. UTIs are more common in women but can affect men after the age of 50.",
        question3: "How do I perform the test?",
        answer3: "Collect a urine sample in the provided plastic cup or a clean cup. Dip the test strip into the urine, ensuring that all four test fields are immersed for about 1-2 seconds. Remove the strip and wipe off any excess urine. Wait for 2 minutes and compare the colours on each test field with the provided colour chart.",
        question4: "How do I interpret the test results?",
        answer4: "Each test field corresponds to a specific analyte in the urine. If the colour of the test field changes as indicated on the colour chart, it suggests the presence of the respective analyte. Positive results for leukocytes and nitrite indicate a UTI is likely and you should contact a doctor or a pharmacist. Blood or protein should be urgently discussed with a doctor for further evaluation.",
        question5: "Can the test results be incorrect?",
        answer5: "The test is most accurate if the instructions are followed correctly, however no test is 100% accurate. Certain medications, dietary factors, or contamination of the urine sample may affect the results. As with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
        question6: "When should I perform the test?",
        answer6: "It is recommended to perform the test with a urine sample collected in the early morning when it is most concentrated. Avoid contamination of the urine sample with water, disinfectants, or cleaning substances.",
        question7: "What should I do if my test result is positive?",
        answer7: "Depending on which analyte is positive, you may have a urinary tract infection. If any of the analytes are positive it is recommended to contact a healthcare professional and provide them with the test result for further evaluation and diagnosis.",
        question8: "What should I do if my test result is negative?",
        answer8: "A negative result means that none of the tested analytes were detected in your urine. However, if you still experience symptoms or have concerns, it is advisable to repeat the test and consult a healthcare professional.",
        question9: "How should I store the test kit?",
        answer9: "Store the test kit in a dry place at temperatures between 2-30 °C (36-86 °F). Do not use the kit if the package is damaged. Keep it out of the reach of children.",
        question10: "Can I reuse the test kit?",
        answer10: "No, the test kit is designed for single use only. Do not dismantle or touch the reagent areas of the test strip.",
    },
    {
        id:26,
		categoryid:4,
        name:"Bacterial Vaginosis (BV)",
        image:require("../assets/images/products/bacterial_vaginosis/test-kits_0000_bacterial-vaginosis.png"),
        productBackground:require("../assets/images/products/bacterial_vaginosis/orange_product_background.png"),
        buttonColour:"#DE5929",
        question1: "What is the Vaginal pH Rapid Test Panel (Vaginal Swab) used for?",
        answer1: "This is a self-test used to measure the pH level in a female vaginal swab. It aids in diagnosing bacterial vaginosis, a common type of vaginal infection. Symptoms of bacterial vaginosis include itching, burning and a fishy odour.",
        question2: "How does the test work?",
        answer2: "The test uses a pH paper that changes colour when it comes in contact with the vaginal swab. The colour corresponds to a particular pH level on the colour scale provided with the test unit.",
        question3: "Are there any times when I shouldn't use the test?",
        answer3: "You should avoid using the test 72 hours after using vaginal preparations (like contraceptive creams and antifungal agents), 48 hours after sexual intercourse or douching, and until 5 days after your period has ended.",
        question4: "How do I use the test?",
        answer4: "After washing your hands, take the swab from the package and insert it into your vagina for about 10 seconds to moisten it with vaginal secretions. Then apply the swab to the pH area of the test panel and read the result immediately.",
        question5: "What does a normal result look like?",
        answer5: "If your vaginal pH level is within the normal range (3.8-4.4), the colour of the indicator on the test panel will be Yellow-Green.",
        question6: "What is a normal vaginal pH level?",
        answer6: "A normal vaginal pH level ranges from 3.8 to 4.5. This acidic environment helps protect the vagina from infections as most disease-causing bacteria can't thrive in such conditions. If you have symptoms but your vaginal pH level is in the normal range it is unlikely you have bacterial vaginosis and you may instead have a candida (yeast) infection. Consult a pharmacist or a doctor for further testing and a definitive diagnosis.",
        question7: "What is an abnormal result?",
        answer7: "If your pH level is over 4.4 or under 3.8, it's outside the normal range. The indicator colour will be Green-Blue for pH over 4.4 and Bright Yellow for pH under 3.8. A pH over 4.4 in particular may indicate a vaginal infection, which may be caused by an overgrowth of Gardnerella Vaginalis (“BV”) or an infection with Trichomonas Vaginalis. Consult a physician or a pharmacist for additional testing and a definitive diagnosis.",
        question8: "Can menopausal women use this test?",
        answer8: "Menopausal and peri-menopausal women may have an elevated vaginal pH and could receive an abnormal result even if they're not suffering from an infection.",
        question9: "Does this test detect sexually transmitted diseases?",
        answer9: "No, this test is not designed to detect sexually transmitted diseases such as HIV, chlamydia, herpes, gonorrhea, or syphilis. However, an elevated vaginal pH can occur with some sexually transmitted infections, such as trichomonas. If you have symptoms and an elevated vaginal pH consider undergoing additional testing for sexually transmitted infections. ",
        question10: "How accurate is this test?",
        answer10: "Clinical trials have shown this test to have 94.4% specificity and 98.9% sensitivity, with an overall accuracy of 98.1%. As with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
    },
    {
        id:27,
		categoryid:4,
        name:"Covid-19 & Flu",
        image:require("../assets/images/products/covid_19_flu/test-kits_0010_covid-combo.png"),
        productBackground:require("../assets/images/products/covid_19_flu/orange_product_background.png"),
        buttonColour:"#DE5929",
        question1: "What is this test for?",
        answer1: "This test is for detecting the presence of SARS-CoV-2 (COVID-19), Influenza A, and Influenza B antigens in nasal swab specimens.",
        question2: "How should I prepare for the test?",
        answer2: "Wash your hands with soap and water for at least 20 seconds before and after the test. If soap and water are not available, use hand sanitizer with at least 60% alcohol.",
        question3: "How do I collect the nasal swab specimen?",
        answer3: "Remove the sterile swab from the pouch and gently insert it into your nostril until you feel slight resistance. Twist the swab against the nasal wall for 5-10 times. Repeat the process in the other nostril.",
        question4: "What should I do with the swab after collecting the specimen?",
        answer4: "Place the swab into the extraction tube and stir it for 10-15 seconds. Remove the swab while squeezing the swab head against the inside of the tube. Close the cap or fit the tube tip onto the tube.",
        question5: "How do I perform the test?",
        answer5: "Remove the test cassette from the sealed foil pouch. Invert the specimen extraction tube and add 3 drops of the extracted specimen to each sample well of the test cassette. Read the result at 10 minutes and do not read it after 20 minutes.",
        question6: "How do I interpret the test results?",
        answer6: "Positive results for SARS-CoV-2 or Influenza A/B will show two coloured lines in the respective regions on the test cassette. Negative results will show only one coloured line in the control region. An invalid result occurs if the control line fails to appear.",
        question7: "What should I do if I get a positive result?",
        answer7: "A positive result indicates that you may have COVID-19 and/or Influenza A/B. You should immediately go into self-isolation, contact your healthcare provider, and follow local guidelines for further instructions. ",
        question8: "What does a negative result mean?",
        answer8: "A negative result suggests that you are unlikely to have COVID-19 and/or Influenza A/B. However, false negatives are possible, so if you continue to experience symptoms, you should seek follow-up care.",
        question9: "What should I do if I get an invalid result?",
        answer9: "An invalid result may occur due to procedural errors or insufficient specimen volume. Review the procedure and repeat the test with a new kit or contact a COVID-19 and/or Influenza test center.",
        question10: "Can the test results be incorrect?",
        answer10: "The test is most accurate if the instructions are followed correctly, however no test is 100% accurate. Errors can also occur if the test cassette gets wet, you take an insufficient nasal swab sample, or incorrect procedure is followed. As with any test, false positive and false negative results are possible. If you are uncertain, repeat the test and consult a healthcare professional.",
        question11: "What precautions should I take?",
        answer11: "Read the information provided in the package insert before performing the test. Follow the indicated time strictly, handle the buffer carefully, and store the kit properly. Wash hands thoroughly before and after handling. Observe local COVID-19 and Influenza guidelines and requirements.",
        question12: "How should I store the test kit?",
        answer12: "Store the kit in a dry place at 2-30 °C (36-86 °F) in the sealed pouch. Do not freeze or use the kit after the expiration date printed on the package.",
    },
];
