import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, Pressable, Linking } from 'react-native';
import React from 'react';  
import { Text, View } from "../components/Themed";
import { useNavigation, useRoute } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';

export default function Article1Screen (props) {  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.usefulwrapper}>
        <Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginVertical: 20, flexDirection: "row" }}>
            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: "#000", height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 4}]}>Back to supporting articles</Text>
        </Pressable>

        <Text style={styles.maintitle}>Why rapid testing can help children get back to school safely</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
        
        <Text style={styles.bodybold}>As we approach the last stretch of winter in the Northern Hemisphere, it’s important to be aware that we are still in a cold spell – and in the peak season for flu.</Text>

        <Text style={styles.body}>This year, countries such as the UK continue to see an increase in infections such as scarlet fever and Strep A. While the reason for this is not clear, some suggest it may be due to reduced immunity after the isolation of the pandemic.</Text>

        <Text style={styles.body}>Strep A (Group A Streptococcus) is a highly contagious bacterium that spreads via sneezing and coughing, or through close contact. This is particularly true in school settings where children are in close proximity to one another.</Text>

        <Text style={styles.body}>Early symptoms of Strep A (such as a sore throat and fever) can look similar to a range of other infections. This makes it a particularly <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.theguardian.com/commentisfree/2022/dec/06/strep-a-uk-myths-deaths-children')}>difficult disease to contain</Text> during an indoor mixing season, and clinically it is also hard to diagnose strep A at an early stage when it is seen in primary care.</Text>

        <Text style={styles.body}>Strep A itself is also responsible for causing a wide array of infections – most commonly it causes a sore throat and general malaise that resolves by itself, but this can develop into an illness known as scarlet fever, marked by a bright red rash. This is a lot more common in children 5 to 15 years of age. In very rare cases the bacteria can cause a severe, life-threatening infection known as invasive group A streptococcal disease (iGAS).</Text>

        <Text style={styles.body}>At least five European countries have reported an increase in Strep A infections in recent months, and in some cases, scarlet fever. Reports show that children under ten have been the most affected age group.* The UK, the worst affected country, <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.news-medical.net/news/20221218/Recent-surge-in-group-A-streptococcal-infections-among-children-in-UK.aspx')}>reported</Text> more than 6,600 cases of scarlet fever and 652 cases of invasive group A strep infection over a period of only 12 weeks.</Text>

        <Text style={styles.body}>The UK government recently published a <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.gov.uk/government/publications/group-a-streptococcal-infections-activity-during-the-2022-to-2023-season/group-a-streptococcal-infections-report-on-seasonal-activity-in-england-2022-to-2023')}>report</Text> to highlight the sharp increase in scarlet fever early in the 2022 to 2023 season, which they say is ‘steeper’ than would be expected at this time of the year. The rate of iGAS infections is said to also be following a similar trend, with weekly incidence rates slightly above what would be expected.</Text>

        <Text style={styles.body}>Dr Cosima Gretton, member of the 2San Scientific Advisory Committee, said: “The half-term break will reduce mixing within the school setting, but it also tends to bring about more mixing in a family and social setting. As with Covid, testing using rapid diagnostics can help to identify Strep A infections before they can spread to others. Early identification and treatment can also help to prevent scarlet fever or complications of strep A, such as rheumatic fever. Testing can help ensure the correct treatment is given, potentially reducing the unnecessary use of antibiotics.”</Text>

        <Text style={styles.body}>Other respiratory viruses such as Flu and COVID-19 continue to <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.gov.uk/government/news/back-to-school-advice-issued-amid-high-levels-of-flu-covid-19-and-scarlet-fever')}>circulate at high levels</Text>, and just like Step A, they all have similar symptoms. Rapid testing can continue to provide a valuable tool to differentiate between similar infections, and help people get the treatment needed or isolate until they’ve recovered.</Text>

        <Text style={styles.body}><Text style={styles.bodylink} onPress={() => Linking.openURL('https://2san.com/self-diagnostics')}>Rapid testing</Text> is also important for asymptomatic individuals and can find the <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.gov.uk/government/news/asymptomatic-testing-backed-by-new-research-studies')}>one in three people</Text> with <Text style={styles.bodylink} onPress={() => Linking.openURL('https://2san.com/latest-news/2san-launches-range-of-self-diagnostic-tests-in-the-uk')}>Covid-19</Text> who show no symptoms, for instance, allowing them to isolate rather than unknowingly spread the virus. While life is to some extent “back to normal” for many of us, it’s important we continue to test to reduce transmission to protect the elderly, unvaccinated and people with immune deficiencies – for whom daily life is still limited.</Text>

        <Text style={styles.body}>Dr Gretton adds: "Just as we got used to testing for covid before a gathering, self-testing at home before children return to the classroom can help minimise disruption and ensure they can get back to school safely. Regardless of what’s making you sick, it’s important to stay home until you feel better so that you don’t infect other people.”</Text>

        <Text style={styles.body}>Rapid tests have made self-testing easy to do at home. A rapid Strep A test involves a quick throat swab, and within minutes, the test can identify Group A Streptococcus bacteria, which can cause strep throat and other infections. Similarly, in just ten minutes, a single use test kit can quickly detect the SARS-CoV-2, Influenza A and Influenza B viruses that cause COVID-19 and Influenza, giving people a fast and accurate diagnosis – and peace of mind.</Text>

        <Text style={styles.body}>2San is a supplier of affordable, accessible, sustainable, and high-quality products to the global healthcare market, and supplies a Strep A self-test in the UK. Our test provides an accurate diagnosis in 10 minutes. Our first-of-its-kind <Text style={styles.bodylink} onPress={() => Linking.openURL('https://2san.com/latest-news/new-covid-flu-dual-test-launches-in-the-uk-to-take-pressure-off-the-nhs')}>over-the-counter dual kit</Text> for SARS-CoV-2 & Influenza A+B is currently available to customers in the UK. We also have a dual test available for the Australian market.</Text>

        <Text style={styles.body}>* <Text style={{fontSize: 11,textDecorationLine: 'underline',}} onPress={() => Linking.openURL('https://www.gavi.org/vaccineswork/whats-behind-recent-surge-strep-and-scarlet-fever')}>https://www.gavi.org/vaccineswork/whats-behind-recent-surge-strep-and-scarlet-fever</Text></Text>        

        <Pressable style={styles.backbutton} onPress={() => props.navigation.goBack()}>
          <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff",marginRight: 5}}/><Text style={styles.backbuttontext}>Back to support articles</Text>
        </Pressable>           
        
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 20,
    marginBottom: 40,
    height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%",
  },
  usefulwrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
  },
  maintitle: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  title: {
    fontSize: 25,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  body: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
  },
  bodylink: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
    textDecorationLine: 'underline',
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 13,
    paddingHorizontal: 50,
    marginBottom: 25,
    marginTop: 15,
    backgroundColor: "#000",
    width: "100%",
    flexDirection: "row",
  },
  backbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
  },
});