import React from "react";
import { StyleSheet } from "react-native";
import { primary } from "../constants/Colors";
import { View } from "./Themed";

export default function HamburgerWhite() {
  return (
    <View style={styles.container}>
      <View
        lightColor="#FFF"
        darkColor="#FFF"
        style={[styles.bar, styles.barTop]}
      />
      <View
        lightColor="#FFF"
        darkColor="#FFF"
        style={[styles.bar, styles.barMiddle]}
      />
      <View
        lightColor="#FFF"
        darkColor="#FFF"
        style={[styles.bar, styles.barBottom]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginRight: 25,
    backgroundColor: "transparent",
    paddingLeft: 4,
    paddingTop: 3,

  },
  bar: {
    height: 4,
    marginBottom: 6,
    borderRadius: 0,
  },
  barTop: {
    width: 28,
  },
  barMiddle: {
    width: 28,
  },
  barBottom: {
    width: 28,
  },
});
