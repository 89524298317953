import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, Pressable } from 'react-native';
import React from 'react';  
import { Text, View } from "../components/Themed";
import { useNavigation, useRoute } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';

export default function SupportingArticlesScreen (props) {  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.usefulwrapper}>
        <Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginVertical: 20, flexDirection: "row" }}>
            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: "#000", height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 4}]}>Back to menu</Text>
        </Pressable>

        <Text style={styles.maintitle}>Supporting Articles</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
        
        <Text style={styles.body}>Below are articles to better help you understand the importance of self-testing, browse through and click on the article you would like to read.</Text>
        
        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
        
        <View style={{marginBottom: 10,}}>
          <Pressable onPress={() => props.navigation.navigate('article1')} style={{aspectRatio: 1200 / 446, width: "100%"}}>
            <Image style={{width: "100%", height: "100%", resizeMode: "cover"}} source={require("../assets/images/article1-thumb.jpg")} />
          </Pressable>
        </View>
        <Text style={styles.bodylink} onPress={() => props.navigation.navigate('article1')}>Why rapid testing can help children get back to school safely.</Text>

        <View style={{marginBottom: 10, marginTop: 15}}>
          <Pressable onPress={() => props.navigation.navigate('article2')} style={{aspectRatio: 1200 / 446, width: "100%"}}>
            <Image style={{width: "100%", height: "100%", resizeMode: "cover"}} source={require("../assets/images/article2-thumb.jpg")} />
          </Pressable>
        </View>
        <Text style={styles.bodylink} onPress={() => props.navigation.navigate('article2')}>Understanding sperm count and what you can do about it.</Text>

        <View style={{marginBottom: 10, marginTop: 15}}>
          <Pressable onPress={() => props.navigation.navigate('article3')} style={{aspectRatio: 1200 / 446, width: "100%"}}>
            <Image style={{width: "100%", height: "100%", resizeMode: "cover"}} source={require("../assets/images/article3-thumb.jpg")} />
          </Pressable>
        </View>
        <Text style={styles.bodylink} onPress={() => props.navigation.navigate('article3')}>How to test your Vitamin D levels at home.</Text>

        <View style={{marginBottom: 10, marginTop: 15}}>
          <Pressable onPress={() => props.navigation.navigate('article4')} style={{aspectRatio: 1200 / 446, width: "100%"}}>
            <Image style={{width: "100%", height: "100%", resizeMode: "cover"}} source={require("../assets/images/article4-thumb.jpg")} />
          </Pressable>
        </View>
        <Text style={styles.bodylink} onPress={() => props.navigation.navigate('article4')}>Tips for trying to conceive.</Text>

        <Pressable style={styles.backbutton} onPress={() => props.navigation.goBack()}>
          <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff",marginRight: 5}}/><Text style={styles.backbuttontext}>Back to menu</Text>
        </Pressable>        
        
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 20,
    marginBottom: 40,
    height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%",
  },
  usefulwrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
  },
  maintitle: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  title: {
    fontSize: 25,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  body: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodylink: {
    fontSize: 16,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 13,
    paddingHorizontal: 50,
    marginBottom: 25,
    marginTop: 15,
    backgroundColor: "#000",
    width: "100%",
    flexDirection: "row",
  },
  backbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
  },
});