import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, Pressable, Linking } from 'react-native';
import React from 'react';  
import { Text, View } from "../components/Themed";
import { useNavigation, useRoute } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';

export default function Article3Screen (props) {  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.usefulwrapper}>
        <Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginVertical: 20, flexDirection: "row" }}>
            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: "#000", height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 4}]}>Back to supporting articles</Text>
        </Pressable>

        <Text style={styles.maintitle}>How to test your Vitamin D levels at home</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
        
        <Text style={styles.bodybold}>Are you feeling exhausted and susceptible to frequent colds? You're not alone. Over 20% of adults suffer from Vitamin D deficiency, a condition that arises when the body doesn't receive sufficient vitamin D from sunlight or diet. Low levels of Vitamin D can cause symptoms such as low mood, fatigue and frequent illness. We recommend using the rapid 2San Vitamin D Deficiency Test — an essential tool for identifying deficiencies and taking proactive step to get your health back on track.</Text>
        
        <Text style={styles.body}>Vitamin D is an essential fat-soluble vitamin that’s essential for healthy bones, muscles, and a strong immune system. It’s also important for your mental health, with low levels being linked to depression.</Text>

        <Text style={styles.bodybold}>You get vitamin D in three ways:</Text>

        <View style={{flexDirection: 'row'}}>
          <Text>{'\u2022'}</Text>
          <Text style={[styles.body, {flex: 1, paddingLeft: 5, marginBottom: 5}]}>When your bare skin is exposed to sunlight, your body naturally makes Vitamin D. Safe sun exposure is the best source of vitamin D.</Text>
        </View>

        <View style={{flexDirection: 'row'}}>
          <Text>{'\u2022'}</Text>
          <Text style={[styles.body, {flex: 1, paddingLeft: 5, marginBottom: 5}]}>Vitamin D can also be found in a small number of foods, such as egg yolks and fatty fish, and many fortified products, including breakfast cereals, milk, and other dairy items.</Text>
        </View>

        <View style={{flexDirection: 'row'}}>
          <Text>{'\u2022'}</Text>
          <Text style={[styles.body, {flex: 1, paddingLeft: 5}]}>Another source are vitamin D supplements.</Text>
        </View>

        <Text style={styles.bodybold}>Why is Vitamin D important?</Text>

        <Text style={styles.body}>Vitamin D plays a vital role in your overall well-being. It helps to absorb calcium in your gut and maintains optimal levels of calcium and phosphate in your body. It also helps keep your muscles, nerves, and immune system functioning normally.</Text>

        <Text style={styles.body}>When your skin is exposed to sunlight, your body makes vitamin D naturally. But in certain countries during the autumn and winter seasons, sun exposure is limited, and the strength of the sun is weak, your vitamin D levels tend to drop. This makes Vitamin D deficiency quite common. The main signs of Vitamin D deficiency include low energy levels, bone and muscle discomfort, and getting ill more frequently than usual.</Text>

        <Text style={styles.bodybold}>Why would you benefit from a Vitamin D test?</Text>

        <Text style={styles.body}>Before your body can fully use vitamin D, whether, from sunlight or food, your liver has to change it into another form called 25 hydroxyvitamin D, or simply 25(OH)D. Most vitamin D tests measure the level of 25(OH)D in your blood. Your kidneys use 25(OH)D to make "active vitamin D", which allows your body to absorb calcium to help with bone development and help other cells function properly.</Text>

        <Text style={styles.body}>The 2San Vitamin D Deficiency Test is used to screen for low levels of vitamin D in your blood to let you know if you need to increase your vitamin D levels.</Text>

        <Text style={styles.body}>While Vitamin D plays a pivotal role in energy levels and overall health, it's essential to know that other factors, such as iron levels and thyroid function, also contribute significantly. Alongside the Vitamin D test, we also recommend these additional tests to gain a comprehensive overview of your overall health.</Text>

        <Text style={styles.body}><Text style={{fontSize: 12}}>Sources</Text></Text>

        <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.healthline.com/nutrition/vitamin-d-deficiency-symptoms')}><Text style={{fontSize: 12}}>https://www.healthline.com/nutrition/vitamin-d-deficiency-symptoms</Text></Text>
        
        <Pressable style={styles.backbutton} onPress={() => props.navigation.goBack()}>
          <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff",marginRight: 5}}/><Text style={styles.backbuttontext}>Back to support articles</Text>
        </Pressable>           
        
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 20,
    marginBottom: 40,
    height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%",
  },
  usefulwrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
  },
  maintitle: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  title: {
    fontSize: 25,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  body: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
  },
  bodylink: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
    textDecorationLine: 'underline',
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 13,
    paddingHorizontal: 50,
    marginBottom: 25,
    marginTop: 15,
    backgroundColor: "#000",
    width: "100%",
    flexDirection: "row",
  },
  backbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
  },
});