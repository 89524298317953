import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, Pressable, Linking } from 'react-native';
import React from 'react';  
import { Text, View } from "../components/Themed";
import { useNavigation, useRoute } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';

export default function ContactSupportScreen (props) {  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.productcontactwrapper}>
        <Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginVertical: 20, flexDirection: "row" }}>
            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: "#000", height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 4}]}>Back to menu</Text>
        </Pressable>

        <Text style={styles.title}>Support</Text>
        
        <Text style={styles.body}>For more information on our products you can contact 2San at their address:</Text>
        
        <Text style={styles.bodybold}>Unit 11 Carnforth Business Park,{"\n"}
        Oakwood Way, Carnforth,{"\n"}
        England, LA5 9FD</Text>                
        
        {Platform.OS === "web" ? (
          <a href="mailto:helpdesk@2san.com" style={{backgroundColor: "#000", color:"#fff",marginBottom:15, height:46, display:"flex", textDecoration:"none", textAlign:"center", fontFamily: 'GalanoGrotesqueBold', fontSize:16, alignItems:"center", justifyContent:"center" }}>Email: helpdesk@2san.com<Image style={{marginLeft: 15, width: 21, height: 16 }} source={require("../assets/images/envelope-icon.png")} /></a>
        ) : (
          <Pressable style={styles.feedbackbutton} onPress={() => Linking.openURL("mailto:helpdesk@2san.com")}>
            <Text style={styles.feedbackbuttontext}>Email: helpdesk@2san.com</Text><Image style={{width: 21, height: 16 }} source={require("../assets/images/envelope-icon.png")} />
          </Pressable>
        )}

        <Text style={styles.body}>They also have a website at <Text style={styles.bodybold} onPress={() => Linking.openURL('https://www.2san.com')}>www.2san.com</Text></Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>

        <Text style={styles.body}>Please note that for specific medical advice and interpretation of the results, it is important to consult a healthcare professional.</Text>
        
        <Text style={styles.body}>Remember, while these home tests can provide some useful insights, they should not be used as a substitute for a formal evaluation by a healthcare provider, especially if symptoms persist.</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>

        <Text style={styles.title}>Feedback</Text>

        <Text style={styles.body}>We appreciate all feedback we receive, let us know what you think by clicking the button below.</Text>

        {Platform.OS === "web" ? (
          <a href="mailto:helpdesk@2san.com?subject=2San%20Feedback" style={{backgroundColor: "#000", color:"#fff",marginBottom:15, height:47, display:"flex", textDecoration:"none", textAlign:"center", fontFamily: 'GalanoGrotesqueBold', fontSize:16, alignItems:"center", justifyContent:"center" }}>Tell us your feedback<Image style={{marginLeft: 15, width: 21, height: 16 }} source={require("../assets/images/envelope-icon.png")} /></a>
        ) : (
          <Pressable style={styles.feedbackbutton} onPress={() => Linking.openURL("mailto:helpdesk@2san.com?subject=2San%20Feedback")}>
            <Text style={styles.feedbackbuttontext}>Tell us your feedback</Text><Image style={{width: 21, height: 16 }} source={require("../assets/images/envelope-icon.png")} />
          </Pressable>
        )}
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 20,
    marginBottom: 40,
    height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%",
  },
  productcontactwrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
  },
  title: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 15,
  },
  body: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
  },
  feedbackbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 13,
    paddingHorizontal: 5,
    marginBottom: 15,
    backgroundColor: "#000",
    flexDirection: "row",
  },
  feedbackbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
    marginRight: 15,
  },
});