export default [
    {
        id:23,
		categoryid:1,
        name:"Iron Deficiency",
        image:require("../assets/images/products/iron_deficiency/test-kits_0007_iron-deficiency.png"),
        accuracyIcon:require("../assets/images/products/iron_deficiency/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/iron_deficiency/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/iron_deficiency/green_product_background.png"),
        packsizeIcon:require("../assets/images/products/iron_deficiency/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/iron_deficiency/specimen_icon.png"),
        buttonColour:"#3B8046",
        gradientBackgroundColour2:"#93B961",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/iron_def_draft_v1.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/iron_deficiency_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/iron-def-thumb.jpg"),
        nextSteps:"If the test indicates low ferritin, you may have iron deficiency anemia. It is important to consult a doctor as there are many causes of iron deficiency, some of which can be serious. The doctor can evaluate your symptoms and order additional tests to confirm the diagnosis. If there is no other underlying cause, they may recommend dietary changes to include iron-rich foods like red meat, leafy greens, and beans. In some cases, iron supplements may be prescribed to help increase your iron levels.",
        nextProductId: 24
    },
    {
        id:24,
		categoryid:1,
        name:"Vitamin D Deficiency",
        image:require("../assets/images/products/vitamin_d_deficiency/test-kits_0004_vitamin-d-deficiency.png"),
        accuracyIcon:require("../assets/images/products/vitamin_d_deficiency/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/vitamin_d_deficiency/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/vitamin_d_deficiency/green_product_background.png"),
        packsizeIcon:require("../assets/images/products/vitamin_d_deficiency/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/vitamin_d_deficiency/specimen_icon.png"),
        buttonColour:"#3B8046",
        gradientBackgroundColour2:"#93B961",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/vit_d_draft_v2.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/vitamin_d_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/vit-d-thumb.jpg"),
        nextSteps:"If the test indicates a vitamin D deficiency, discuss this with your local pharmacist. You may want to visit a doctor to assess your symptoms and order a blood test to confirm the result. They may recommend increasing safe sun exposure or taking vitamin D supplements to help raise your levels. Consuming foods rich in vitamin D, such as fatty fish, fortified dairy products, and egg yolks, can be beneficial.",
        nextProductId: 21
    },
    {
        id:21,
		categoryid:1,
        name:"Hypothyroidism (Underactive Thyroid)",
        image:require("../assets/images/products/hypothyroidism/test-kits_0008_hypothyroidism.png"),
        accuracyIcon:require("../assets/images/products/hypothyroidism/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/hypothyroidism/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/hypothyroidism/green_product_background.png"),
        packsizeIcon:require("../assets/images/products/hypothyroidism/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/hypothyroidism/specimen_icon.png"),
        buttonColour:"#3B8046",
        gradientBackgroundColour2:"#93B961",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/thyroid_draft_v2.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/hypothyroidism_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/hypothyroidism-thumb.jpg"),
        nextSteps:"If the test suggests high levels of TSH, it is important to consult a healthcare professional for assessment of your symptoms and repeat blood tests to confirm any diagnosis. If the test is negative but you still have symptoms, it’s important to consult a healthcare professional, as some types of hypothyroidism can present with a normal TSH. Your doctor can advise on the best treatment options depending on any additional tests they run.",
        nextProductId: 19
    },
    {
        id:19,
		categoryid:1,
        name:"Bowel Cancer",
        image:require("../assets/images/products/bowel_cancer/test-kits_0011_bowel-cancer.png"),
        accuracyIcon:require("../assets/images/products/bowel_cancer/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/bowel_cancer/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/bowel_cancer/green_product_background.png"),
        packsizeIcon:require("../assets/images/products/bowel_cancer/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/bowel_cancer/specimen_icon.png"),
        buttonColour:"#3B8046",
        gradientBackgroundColour2:"#93B961",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/bowel_cancer_draft_v1.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/bowel_cancer_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/bowel-cancer-thumb.jpg"),
        nextSteps:"If you have symptoms and/or are over 45 and the test indicates the presence of hidden or ‘occult’ blood, contact your doctor to discuss your symptoms and test results. Occult blood can indicate several conditions, from anal fissures to inflammatory bowel disease or bowel cancer. The doctor will likely repeat the test and do additional blood tests to confirm the results and potentially recommend further investigations.",
        nextProductId: 20
    },
    {
        id:20,
        name:"Stomach Ulcer",
		categoryid:1,
        image:require("../assets/images/products/stomach_ulcer/test-kits_0006_stomach-ulcer-h-pylori.png"),
        accuracyIcon:require("../assets/images/products/stomach_ulcer/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/stomach_ulcer/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/stomach_ulcer/green_product_background.png"),
        packsizeIcon:require("../assets/images/products/stomach_ulcer/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/stomach_ulcer/specimen_icon.png"),
        buttonColour:"#3B8046",
        gradientBackgroundColour2:"#93B961",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/stomach_ulcer_draft_v2.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/stomach_ulcer_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/stomach-ulcer-thumb.jpg"),
        nextSteps:"If the test suggests the presence of the h.pylori bacterium and you have symptoms of a stomach ulcer, it is important to consult a healthcare professional. They can evaluate your symptoms and may perform additional tests to confirm the diagnosis. Note that the 2San test only detects the presence of h.pylori, which is a common cause of stomach ulcers, it does not indicate whether or not you have a stomach ulcer. Treatment for h.pylori may involve antibiotics, and if a doctor suspects a stomach ulcer they will make separate treatment recommendations.",
        nextProductId: 279
    },
    {
        id:279,
        name:"Menopause (FSH)",
        categoryid:1,
        image:require("../assets/images/products/menopause/test-kits_menopause.png"),
        accuracyIcon:require("../assets/images/products/menopause/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/menopause/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/menopause/green_product_background.png"),
        packsizeIcon:require("../assets/images/products/menopause/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/menopause/specimen_icon.png"),
        buttonColour:"#3B8046",
        gradientBackgroundColour2:"#93B961",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/menopause_draft_v1.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/menopause_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/menopause-thumb.jpg"),
        nextSteps:"If the test indicates high FSH, you are over 45 and/or you have symptoms of menopause, it is advisable to consult a healthcare professional, such as a gynecologist to confirm a diagnosis of the menopause. They can assess your symptoms and provide guidance on managing menopause-related changes. They may recommend lifestyle modifications or medications to alleviate symptoms like hot flashes, mood swings, and vaginal dryness. Regular check-ups and discussions with your healthcare provider can help you navigate this transitional phase of life.",
        nextProductId: 22
    },
    {
        id:22,
		categoryid:2,
        name:"Drugs of Abuse",
        image:require("../assets/images/products/drugs_of_abuse/test-kits_0009_drug-of-abuse.png"),
        accuracyIcon:require("../assets/images/products/drugs_of_abuse/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/drugs_of_abuse/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/drugs_of_abuse/blue_product_background.png"),
        packsizeIcon:require("../assets/images/products/drugs_of_abuse/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/drugs_of_abuse/specimen_icon.png"),
        buttonColour:"#2F6B9D",
        gradientBackgroundColour2:"#85B3D9",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/drug_test_draft_v3.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/drugs_of_abuse_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/drugs-abuse-thumb.jpg"),
        nextSteps:"If the test indicates the presence of a particular drug or drugs, it is advisable to consult a healthcare professional or seek addiction support services. If you are using the test in the context of a pre-employment screen, the test must be repeated under controlled, observed conditions to be valid, and therefore it is important to note that the results of a self-test are for your own use and information.",
        nextProductId: 28
    },
    {
        id:28,
		categoryid:3,
        name:"Male Fertility",
        image:require("../assets/images/products/male_fertility/test-kits_0003_male-fertility.png"),
        accuracyIcon:require("../assets/images/products/male_fertility/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/male_fertility/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/male_fertility/purple_product_background.png"),
        packsizeIcon:require("../assets/images/products/male_fertility/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/male_fertility/specimen_icon.png"),
        buttonColour:"#300470",
        gradientBackgroundColour2:"#B8227B",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/male_fertility_draft_v1.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/male_fertility_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/male-fert-thumb.jpg"),
        nextSteps:"If the test suggests low sperm count, it is recommended to consult with a healthcare professional, such as a reproductive specialist. They can evaluate your overall health, perform a comprehensive fertility assessment, and provide guidance on potential causes and treatment options. This may involve lifestyle modifications, medication, or assisted reproductive techniques depending on the underlying factors contributing to infertility.",
        nextProductId: 29
    },
    {
        id:29,
		categoryid:3,
        name:"Peak Fertility",
        image:require("../assets/images/products/peak_fertility/test-kits_0001_peak-fertility.png"),
        accuracyIcon:require("../assets/images/products/peak_fertility/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/peak_fertility/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/peak_fertility/purple_product_background.png"),
        packsizeIcon:require("../assets/images/products/peak_fertility/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/peak_fertility/specimen_icon.png"),
        buttonColour:"#300470",
        gradientBackgroundColour2:"#B8227B",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/peak_fertility_draft_v2.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/peak_fertility_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/peak-fert-thumb.jpg"),
        nextSteps:"A positive LH test means you are likely ovulating, and it is a good time to try to conceive. A negative LH test means you are not ovulating right now; however, this must not be used for contraception as sperm can survive for several days in the vagina and you may still conceive if you have intercourse when the test is negative. If you are concerned, consult a healthcare professional who specializes in reproductive health, such as a gynecologist or fertility specialist. They can provide you with information and guidance about tracking your menstrual cycle, identifying signs of ovulation, and optimizing your chances of conceiving.",
        nextProductId: 30
    },
    {
        id:30,
		categoryid:3,
        name:"Early Pregnancy",
        image:require("../assets/images/products/early_pregnancy/test-kits_0002_early-pregnancy.png"),
        accuracyIcon:require("../assets/images/products/early_pregnancy/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/early_pregnancy/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/early_pregnancy/purple_product_background.png"),
        packsizeIcon:require("../assets/images/products/early_pregnancy/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/early_pregnancy/specimen_icon.png"),
        buttonColour:"#300470",
        gradientBackgroundColour2:"#B8227B",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/early_preg_draft_v1.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/hcg_pregnancy_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/early-preg-thumb.jpg"),
        nextSteps:"If the test indicates you are pregnant, it is important to schedule an appointment with a healthcare professional, typically an obstetrician or a midwife. They can confirm the pregnancy and provide prenatal care guidance. They may recommend prenatal vitamins, lifestyle adjustments, and regular check-ups to ensure a healthy pregnancy.",
        nextProductId: 25
    },
    {
        id:25,
		categoryid:4,
        name:"Urinary Tract Infection (UTI)",
        image:require("../assets/images/products/urinary_tract_infection/test-kits_0005_urinary-tract-infection-uti.png"),
        accuracyIcon:require("../assets/images/products/urinary_tract_infection/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/urinary_tract_infection/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/urinary_tract_infection/orange_product_background.png"),
        packsizeIcon:require("../assets/images/products/urinary_tract_infection/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/urinary_tract_infection/specimen_icon.png"),
        buttonColour:"#DE5929",
        gradientBackgroundColour2:"#DE3955",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/uti_draft_v1.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/uti_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/uti-thumb.jpg"),
        nextSteps:"If the test indicates high nitrites and high leukocytes, you may have a urinary tract infection (UTI). It is important to seek medical advice, such as contacting your general practitioner or a healthcare professional, who can confirm the infection and prescribe appropriate antibiotics to treat the UTI.",
        nextProductId: 26
    },
    {
        id:26,
		categoryid:4,
        name:"Bacterial Vaginosis (BV)",
        image:require("../assets/images/products/bacterial_vaginosis/test-kits_0000_bacterial-vaginosis.png"),
        accuracyIcon:require("../assets/images/products/bacterial_vaginosis/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/bacterial_vaginosis/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/bacterial_vaginosis/orange_product_background.png"),
        packsizeIcon:require("../assets/images/products/bacterial_vaginosis/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/bacterial_vaginosis/specimen_icon.png"),
        buttonColour:"#DE5929",
        gradientBackgroundColour2:"#DE3955",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/bacterial_vaginosis_draft_v1.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/bacterial_vaginosis_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/bacterial-vaginosis-thumb.jpg"),
        nextSteps:"If the test suggests bacterial vaginosis (BV), it is advisable to consult a healthcare professional, such as a gynecologist or a sexual health clinic. They can confirm the diagnosis and recommend appropriate treatment options. BV is typically treated with antibiotics, such as oral medication or vaginal gels or creams. It is important to complete the full course of treatment and attend follow-up appointments, if necessary, to ensure the infection is properly cleared. BV can be sexually transmitted so you may need additional tests for other sexually transmitted infections such as chlamydia.",
        nextProductId: 27
    },
    {
        id:27,
		categoryid:4,
        name:"Covid-19 & Flu",
        image:require("../assets/images/products/covid_19_flu/test-kits_0010_covid-combo.png"),
        accuracyIcon:require("../assets/images/products/covid_19_flu/accuracy_icon.png"),
        timetoresultIcon:require("../assets/images/products/covid_19_flu/time_to_result_icon.png"),
        productBackground:require("../assets/images/products/covid_19_flu/orange_product_background.png"),
        packsizeIcon:require("../assets/images/products/covid_19_flu/pack_size_icon.png"),
        specimenIcon:require("../assets/images/products/covid_19_flu/specimen_icon.png"),
        buttonColour:"#DE5929",
        gradientBackgroundColour2:"#DE3955",
        video:"https://2san.com/themes/twosan-theme/assets/videos/uk/covid_19_draft_v1.mp4",
        pdf:"https://2san.com/themes/twosan-theme/assets/pdfs/uk/covid_inf_dual_uk_2San_IFU.pdf",
        videoThumb:require("../assets/images/video-thumbs/sars-cov-thumb.jpg"),
        nextSteps:"If you have a positive result for either Flu or COVID-19, follow local guidelines with regards to self-isolation. Even with the Flu, you may want to avoid contact with others, particularly the elderly or immune compromised, so as not to pass on the infection. Stay updated on public health guidelines, practice good hygiene, and get vaccinated to protect yourself and others from COVID-19 and the Flu.",
        nextProductId: 23
    },
];
