import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, ImageBackground, Pressable, Linking, TouchableOpacity } from 'react-native';
import React, {useState, useEffect, useRef} from 'react';  

import { useRoute } from '@react-navigation/native';

import { Text, View } from "../components/Themed";
import prodLinks from "../components/ProductList";
import HamburgerWhite from "../components/HamburgerWhite";
import { LinearGradient } from 'expo-linear-gradient';

import { Video, ResizeMode } from 'expo-av';
import * as ScreenOrientation from 'expo-screen-orientation';

import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';
import { Collapse, CollapseHeader, CollapseBody } from 'accordion-collapse-react-native';
import { MaterialIcons } from '@expo/vector-icons';

import AsyncStorage from '@react-native-async-storage/async-storage';

const baseUrl = 'https://2san.com/api/v1/GB';
import axios from 'axios';
let prodid=null;
let xxx="?";
let product=null;

let model = "";
let prod=null;
let prevprod=null;

let current1=false;
let current2=false;
let current3=false;
let current4=false;
let currents = [false, false, false, false, false];

let accordionicon_open = require("../assets/images/accordion-open.png");
let accordionicon_closed = require("../assets/images/accordion-closed.png");
let accordion1icon = require("../assets/images/accordion-closed.png");
let accordion2icon = require("../assets/images/accordion-closed.png");
let accordion3icon = require("../assets/images/accordion-closed.png");
let accordion4icon = require("../assets/images/accordion-closed.png");

let hasRated = null;

export default function ProductDetailsScreen (props) {  
	// const { params } = props.params;
	// const id = JSON.stringify(params);

	if (typeof props.route.params !== 'undefined') {
		if (typeof props.route.params.params.id !== 'undefined') {
			prodid = props.route.params.params.id;
		}
		else
		{		
		}
	}
	else
	{
	}

	const [stateProduct, setstateProduct] = useState(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollViewRef = useRef();
	
	useEffect(() => {
		getProduct();
		
		// check if previous rating is stored
		if (prodid !== null)
		{
			AsyncStorage.getItem('id'+prodid).then(value => {
			  if (value != null)
			  {
			    hasRated = value;
			    //console.log(hasRated);
			  } else {
			 		hasRated = null;
			  }
		  });
		}
	});

	// used for android version when video is in fullscreen mode
	function setOrientation() {
    if (Dimensions.get('window').height > Dimensions.get('window').width) {
      //Device is in portrait mode, rotate to landscape mode.
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
    }
    else {
      //Device is in landscape mode, rotate to portrait mode.
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
    }
  }

	const getProduct = () => {
		if (prodid !== null)
		{
			axios.get(`${baseUrl}/product/get/${prodid}?key=VjxFgtPrzxFT5Y3Odtp27XLazcA4jJGefY7vIXGQ38kw6lrYqt`)
				.then(res => {
					//this.setState({ usersCollection: res.data });
					//alert(JSON.stringify(res.data));
					//console.log(prevprod);
					//console.log(prodid);
					if (prevprod !== prodid)
					{
						current1=false;
						current2=false;
						current3=false;
						current4=false;
						currents = [false, false, false, false, false];
						accordion1icon = require("../assets/images/accordion-closed.png");
						accordion2icon = require("../assets/images/accordion-closed.png");
						accordion3icon = require("../assets/images/accordion-closed.png");
						accordion4icon = require("../assets/images/accordion-closed.png");
						prevprod = prodid;
						setStarRating(0);
						//console.log("A");
						//console.log(current1);
						//console.log(current2);
						//console.log(current3);
					}

					setstateProduct(JSON.stringify(res.data));
					//console.log(prevprod);
					//console.log(prodid);
					//console.log("0");
					//console.log(current1);
					//console.log(current2);
					//console.log(current3);
					//setstateProduct(res.data);
				})
				//.catch(err)
				///return response;
		}
		else
		{
			//setstateProduct(JSON.stringify("xxx"));
		}
		
	}

  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});

  const insets = useSafeAreaInsets();

  // accordion icons swap
  const [isActive, setIsActive] = useState(false);
  const [isActives, setIsActives] = useState([false, false, false, false, false]);
  const handleClick1 = (event) => {
	  currents=isActives;
	  //current1=currents[1];
	  current1=!current1;
	  currents[1] = current1;
	  setIsActives(currents);
      setIsActive((current) => !current);
    //console.log("1");
    //console.log(current1);
    //console.log(current2);
    //console.log(current3);

    if(current1 == false) {
    	accordion1icon = require("../assets/images/accordion-closed.png");
    } else {
    	accordion1icon = require("../assets/images/accordion-open.png");
    }
	//console.log(accordion1icon);
	return true;
  };
  const handleClick2 = (event) => {
	  currents=isActives;
	  //current2=currents[2];
	  current2=!current2;
	  currents[2] = current2;
	  setIsActives(currents) ;
      setIsActive((current) => !current);
    //console.log("2");
    //console.log(current1);
    //console.log(current2);
    //console.log(current3);

    if(current2 == false) {
    	accordion2icon = require("../assets/images/accordion-closed.png");
    } else {
    	accordion2icon = require("../assets/images/accordion-open.png");
    }
	//console.log(accordion2icon);
  };
  const handleClick3 = (event) => {
	  currents=isActives;
	  //current3=currents[3];
	  current3=!current3;
	  currents[3] = current3;
	  setIsActives(currents);
      setIsActive((current) => !current);
    //console.log("3");
    //console.log(current1);
    //console.log(current2);
    //console.log(current3);
    if(current3 == false) {
    	accordion3icon = require("../assets/images/accordion-closed.png");
    } else {
    	accordion3icon = require("../assets/images/accordion-open.png");
    }
	//console.log(accordion3icon);
  };
  const handleClick4 = (event) => {
	  currents=isActives;
	  current4=!current4;
	  currents[4] = current4;
	  setIsActives(currents);
      setIsActive((current) => !current);
    
    if(current4 == false) {
    	accordion4icon = require("../assets/images/accordion-closed.png");
    } else {
    	accordion4icon = require("../assets/images/accordion-open.png");
    }
  };

  // star rating
  const [starRating, setStarRating] = useState(null);
  
	// save product rating to db
  const setRating = async (setStarRating) => {
  	// check if previously stored
  	const existingRating = await AsyncStorage.getItem('id'+prodid);

		if (prodid !== null && existingRating == null)
		{
			axios.post(`https://2san.com/api/v1/gb/product/rate/${prodid}?key=VjxFgtPrzxFT5Y3Odtp27XLazcA4jJGefY7vIXGQ38kw6lrYqt`, {
	    	rating: setStarRating,
			})
			.then(function (response) {
			    //console.log('success');
			})
			.catch(function (error) {
			    //console.log('error');
			});
		}

	};

	// mark as stored in local
	const storedRating = async (value) => {
		if (prodid !== null)
		{ 
		  try {
		  	await AsyncStorage.setItem('id'+prodid, 'rating'+value);
		    //console.log('stored success');
		  } catch (e) {
		    //console.log('stored error');
		  }
		}
	};

	//getProduct();
	//product = stateProduct;
	//alert(prodid);
	if (prodid === null)
	{
		//alert("no");
		//setstateProduct("xxx");		
		React.useEffect(() => { props.navigation.navigate('productmenutext') }, []);
		return true;
	}
	else
	{
		product = JSON.parse(stateProduct);

		if (product != null)
		{
			if(product.hasOwnProperty('model'))
			{
				///xxx = JSON.stringify(product);
				//prod = JSON.parse(xxx);
				model = product.model;
				//model = product.model;
				///xxx = JSON.stringify(props);
				let name_en = product.name.en.replace(/(<([^>]+)>)/gi, "");
				let product_summary_en = product.summary.en.replace(/(<([^>]+)>)/gi, "");
				product_summary_en = product_summary_en.replace(/&nbsp;/g, ' ');
				product_summary_en = product_summary_en.replace(/\n/g, '\n\n');
				
				let product_description_en = product.description.en.replace(/(<([^>]+)>)/gi, "");
				product_description_en = product_description_en.replace(/&nbsp;/g, ' ');
				product_description_en = product_description_en.replace(/\n/g, '\n\n');

				let amazon_link = product.amazon_link;
				let average_rating = product.rating;

				let accuracy = " ";
				let timetoresult = " ";
				let specimentype = " ";
				let packsize = " ";
				let interpretationimage_url = "";
				let interpretationimage_width = 0;
				let interpretationimage_height = 0;
				let components = "";
				let kitimg_urls = new Array;
				let kitimg_alts = new Array;
				let kitimgs = [], i = -1;
				let productsheet = "https://2san.com/pdfs/pdf-brochures/"+product.model+".pdf";

				if(product.accuracy)
				{
					accuracy = product.accuracy;
				}				
				if(product.time_to_result)
				{
					timetoresult = product.time_to_result;
				}
				if(product.specimen_type)
				{
					specimentype = product.specimen_type.name;
				}
				if(product.pack)
				{
					packsize = product.pack;
				}

				// function to grab attribute values from HTML
				function getAttrFromString(str, node, attr) {
				    var regex = new RegExp('<' + node + ' .*?' + attr + '="(.*?)"', "gi"), result, res = [];
				    while ((result = regex.exec(str))) {
				        res.push(result[1]);
				    }
				    return res;
				}

				if(product.components)
				{
					components = product.components;
					kitimg_urls = getAttrFromString(components, 'img', 'src');
					kitimg_alts = getAttrFromString(components, 'img', 'alt');

					// build new array from img src and img alt text arrays
					while ( kitimg_urls[++i] ) { 
  					kitimgs.push( [ kitimg_urls[i], kitimg_alts[i] ] );
					}					
					////console.log(kitimgs);
				}
				
				if(product.interpretation_image)
				{
					interpretationimage_url = "https://2san.com/themes/twosan-theme/assets/"+product.interpretation_image.file.en;
					interpretationimage_width = product.interpretation_image.width;
					interpretationimage_height = product.interpretation_image.height;
				}				

				let thispost=null;
				prodLinks.map((post,i)=>{
					if (post.id == prodid)
					{
						thispost = post;
					}
				} 
				);

				
				return (

				// Passing configuration object to axios

				<ScrollView style={{height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%"}} scrollIndicatorInsets={{ right: 1 }} ref={scrollViewRef}>	
					<View style={styles.container}>
						<View>
			        <Image style={{top: Platform.OS == "web" ? -70 : -30, position: 'absolute', width:"100%", height: 430}} source={thispost.productBackground} resizeMode="cover" />
			      </View>

			      <View style={[styles.headerwrapper, {paddingTop: insets.top, paddingLeft: insets.left, paddingRight: insets.right}]}>
    					<Image style={styles.headerlogo} source={require("../assets/images/2san-logo-white.png")} />
    					{Platform.OS === "web" ? (
    						<Pressable accessibilityLabel = "Link to product menu" onPress={() => props.navigation.navigate('productmenutext')}>
    							<HamburgerWhite />
        				</Pressable>
    					) : (
    						<Pressable  accessibilityLabel = "Link to product menu" onPress={() => {video.current.playFromPositionAsync(0); video.current.pauseAsync();props.navigation.navigate('productmenutext');}}>
    							<HamburgerWhite />
        				</Pressable>
    					)}              		
    				</View>				
							
						<View style={styles.detailswrapper}>
							<View style={styles.imagewrapper}>
								<Image style={styles.productimage} source={thispost.image} />
							</View>
							
							<Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginBottom: 15, flexDirection: "row" }}>
			            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: thispost.buttonColour, height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 5}]}>All products</Text>
			        </Pressable>

							{Platform.OS === "web" ? (
        				<a href="mailto:?subject=Take a look at this - 2San's Diagnostic app&amp;body=https://2san.com/shareapp" style={{backgroundColor: thispost.buttonColour, color:"#fff",marginBottom:14, height:47, display:"flex", textDecoration:"none", textAlign:"center", fontFamily: 'GalanoGrotesqueBold', fontSize:16, alignItems:"center", justifyContent:"center" }}>Share this app<Image style={{marginLeft: 13, width: 21, height: 16 }} source={require("../assets/images/envelope-icon.png")} /></a>
        			) : (
        				<Pressable  accessibilityLabel = "Link to email to share this app" style={[styles.downloadbutton, {backgroundColor: thispost.buttonColour}]} onPress={() => Linking.openURL("mailto:?subject=Take a look at this - 2San's Diagnostic app&body=https://2san.com/shareapp")}>
          				<Text style={styles.downloadbuttontext}>Share this app</Text><Image style={{width: 21, height: 16 }} source={require("../assets/images/envelope-icon.png")} />
          			</Pressable>
							)}

							{Platform.OS === "web" ? (
        				<a href={thispost.pdf} target="_blank" style={{backgroundColor: thispost.buttonColour, color:"#fff",marginBottom:14, height:47, display:"flex", textDecoration:"none", textAlign:"center", fontFamily: 'GalanoGrotesqueBold', fontSize:16, alignItems:"center", justifyContent:"center" }}>Download Instructions for use (IFU)<MaterialIcons name='file-download' size={22} color='white' /></a>
        			) : (
        				<Pressable accessibilityLabel = "Download instructions for use" style={[styles.downloadbutton, {backgroundColor: thispost.buttonColour, marginBottom:15}]} onPress={() => Linking.openURL(thispost.pdf)}>
          				<Text style={styles.downloadbuttontext}>Download Instructions for use (IFU)</Text><MaterialIcons name='file-download' size={22} color='white' />
          			</Pressable>
							)}

							<Pressable style={[styles.faqsbutton, {borderColor: thispost.buttonColour, marginBottom: amazon_link != null ? 14 : 25}]} onPress={() => props.navigation.navigate('productfaqs', {screen: 'ProductFaqsScreen',params: {"id":product.id}})}>
        				<Text style={[styles.downloadbuttontext, {color: thispost.buttonColour}]}>Frequently asked questions</Text>
        			</Pressable>

        			{amazon_link != null ? (
        			<Pressable accessibilityLabel = "Purchase this product" style={[styles.downloadbutton, {backgroundColor: thispost.buttonColour, marginBottom:25}]} onPress={() => Linking.openURL(amazon_link)}>
        				<Text style={styles.downloadbuttontext}>Purchase this product</Text><Image style={{width: 22, height: 21 }} source={require("../assets/images/basket-icon.png")} />
        			</Pressable>
        			) : (
        			<View></View>
        			)}
															
							<Text style={[styles.title, {color: thispost.buttonColour}]}>{ product.heading }</Text>	  
							<Text style={[styles.productsummary, {color: thispost.buttonColour}]}>{ product_summary_en }</Text>
							<Text style={styles.body}>{ product_description_en }</Text>

							{/* how to use */}
							<View style={styles.howtouse}>
							  	<Text style={styles.heading}>How To Use</Text>
							  	
							  	{Platform.OS === "web" ? (
	                	<div style={{position: "relative", width: "100%", paddingBottom: "56.25%"}}>
	                		<video controls src={thispost.video} type="video/mp4" style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} poster={thispost.videoThumb}></video>
	                	</div>
	                ) : (
	                	Platform.OS === "android" ? (
		      					  <Video
								        ref={video}
								        style={{ width: "100%", height: "100%", aspectRatio: (1920 / 1080), backgroundColor: "transparent" }}
								        source={{uri: thispost.video}}
								        useNativeControls
								        resizeMode={ResizeMode.CONTAIN}
								        onFullscreenUpdate={setOrientation}
								        onPlaybackStatusUpdate={status => setStatus(() => status)}
		                  /> 
		                ) : (
		                	<Video
								        ref={video}
								        style={{ width: "100%", height: "100%", aspectRatio: (1920 / 1080), backgroundColor: "transparent" }}
								        source={{uri: thispost.video}}
								        useNativeControls
								        resizeMode={ResizeMode.CONTAIN}
								        onPlaybackStatusUpdate={status => setStatus(() => status)}
		                  />
		                )        
									)}	
							</View>

							{/* interpreting your results */}
							<Collapse isExpanded={current1} style={{marginTop: 15}} touchableOpacityProps={{activeOpacity: 1}} onToggle={handleClick1}>
    						<CollapseHeader>
    								<LinearGradient style={styles.accordionheader} start={{x: 0.4, y: 0}} end={{x: 1, y: 0}} colors={[thispost.buttonColour, thispost.gradientBackgroundColour2]}>
        						<Text style={styles.accordionheadertext}>Interpreting your results</Text><Image style={{width: 20, height: 
        						20 }} source={accordion1icon} />
      						</LinearGradient>
    						</CollapseHeader>
    						<CollapseBody>
								  <View style={styles.interpreting}>
								  	{interpretationimage_width > 0 ? (
								  		<View style={{aspectRatio: interpretationimage_width / interpretationimage_height, width: "100%"}}>
								  			{Platform.OS === "web" ? (
								  				<Image style={{width: "100%", height: "100%", resizeMode: "cover"}} source={interpretationimage_url} />
								  			) : (
								  				<Image style={{width: "100%", height: "100%", resizeMode: "cover"}} src={interpretationimage_url} />
								  			)}
								  		</View>
								  	) : (
								  		<View></View>
								  	)}

								  	<Text style={[styles.bodybold, {marginVertical: 20, width: "100%"}]}>Concerned about your result?</Text>
										<Text style={styles.body}>Bring a photo of your test results to the pharmacist in the store you bought the test to discuss your result.</Text>
								  </View>
								</CollapseBody>
							</Collapse>

							{/* next steps */}
							<Collapse isExpanded={current4} touchableOpacityProps={{activeOpacity: 1}} onToggle={handleClick4}>
    							<CollapseHeader>
    								<LinearGradient style={styles.accordionheader} start={{x: 0.4, y: 0}} end={{x: 1, y: 0}} colors={[thispost.buttonColour, thispost.gradientBackgroundColour2]}>
        						<Text style={styles.accordionheadertext}>Next steps</Text><Image style={{width: 20, height: 
        						20 }} source={accordion4icon} />
      								</LinearGradient>
    							</CollapseHeader>
    							<CollapseBody>
									<View style={styles.nextstepswrapper}>
								  		<Text style={styles.body}>{thispost.nextSteps}</Text>
								  	</View>
								</CollapseBody>
							</Collapse>

							{/* product specifics */}
							<Collapse isExpanded={current2} touchableOpacityProps={{activeOpacity: 1}} onToggle={handleClick2}>
    						<CollapseHeader>
      						<LinearGradient style={styles.accordionheader} start={{x: 0.4, y: 0}} end={{x: 1, y: 0}} colors={[thispost.buttonColour, thispost.gradientBackgroundColour2]}>
        						<Text style={styles.accordionheadertext}>Product specifics</Text><Image style={{width: 20, height: 
        						20 }} source={accordion2icon} />
      						</LinearGradient>
    						</CollapseHeader>
    						<CollapseBody>
      						<View style={styles.featureswrapper}>
								  	<View style={styles.featurewrapper}>
								  		<View style={styles.featureproductimagewrapper}>
								  			<Image style={{height:45,width:45}} source={thispost.accuracyIcon} />
								  		</View>
								  		<View style={{flexShrink: 1}}>
								  			<Text style={styles.bodybold}>Accuracy</Text>
								  			<Text style={styles.body}>{ accuracy }</Text>
								  		</View>
								  	</View>

								  	<View style={styles.featurewrapper}>
								  		<View style={styles.featureproductimagewrapper}>
									  		<Image style={{height:44,width:41,marginRight:1,marginLeft:3}} source={thispost.timetoresultIcon} />
									  	</View>
									  	<View style={{flexShrink: 1}}>
								  			<Text style={styles.bodybold}>Time to result</Text>
								  			<Text style={styles.body}>{ timetoresult }</Text>
								  		</View>
								  	</View>
								  	
								  	<View style={styles.featurewrapper}>
								  		<View style={styles.featureproductimagewrapper}>
								  			<Image style={{height:47,width:19,marginRight:13,marginLeft:13}} source={thispost.specimenIcon} />
								  		</View>
								  		<View style={{flexShrink: 1}}>
								  			<Text style={styles.bodybold}>Specimen type</Text>
								  			<Text style={styles.body}>{ specimentype }</Text>
								  		</View>
								  	</View>							  							  	
								  	
								  	<View style={styles.featurewrapper}>
								  		<View style={styles.featureproductimagewrapper}>
									  		<Image style={{height:50,width:20,marginRight:12,marginLeft:12}} source={thispost.packsizeIcon} />
									  	</View>
									  	<View style={{flexShrink: 1}}>
								  			<Text style={styles.bodybold}>No. of tests per pack</Text>
								  			<Text style={styles.body}>{ packsize }</Text>
								  		</View>
								  	</View>
								  </View>
    						</CollapseBody>
							</Collapse>

							{/* whats in the kit */}
							<Collapse isExpanded={current3} touchableOpacityProps={{activeOpacity: 1}} onToggle={handleClick3}>
    						<CollapseHeader>
      						<LinearGradient style={styles.accordionheader} start={{x: 0.4, y: 0}} end={{x: 1, y: 0}} colors={[thispost.buttonColour, thispost.gradientBackgroundColour2]}>
        						<Text style={styles.accordionheadertext}>What's in the kit?</Text><Image style={{width: 20, height: 
        						20 }} source={accordion3icon} />
      						</LinearGradient>
    						</CollapseHeader>
    						<CollapseBody>
								  <View style={styles.whatsinthekitwrapper}>
								  	{kitimgs.map((kitimg, i)=>{
											return <View style={styles.kitwrapper} key={i}>
									  		<View style={styles.kitimagewrapper}>
									  		{Platform.OS === "web" ? (
								  				<View style={styles.kitimgbox}><Image style={styles.kitimage} source={kitimgs[i][0]} /></View>
								  			) : (
								  				<View style={styles.kitimgbox}><Image style={styles.kitimage} src={kitimgs[i][0]} /></View>
								  			)}
									  		</View>
									  		<Text style={styles.kittext}>{kitimgs[i][1]}</Text>
								  		</View>
								  	})}
								  </View>
								</CollapseBody>
							</Collapse>

							{/* star ratings */}
							<View style={styles.starratingwrapper}>
							  	<Text style={styles.heading}>Tap to rate this product: {starRating ? `${starRating}*` : ''}</Text>
							  	<View style={[styles.starwrapper, {opacity: hasRated == null ?  1 : 0.2}]}>
							  		<TouchableOpacity onPress={() => {setStarRating(1);setRating(1);storedRating(1);}} disabled={hasRated == null ? false : true}>
							  			<MaterialIcons name={starRating >= 1 ? 'star' : 'star-border'} size={50} style={[starRating >= 1 ? styles.starSelected : styles.starUnselected, {color: thispost.buttonColour}]} />
					          </TouchableOpacity>
					          <TouchableOpacity onPress={() => {setStarRating(2);setRating(2);storedRating(2);}} disabled={hasRated == null ? false : true}>
					          	<MaterialIcons name={starRating >= 2 ? 'star' : 'star-border'} size={50} style={[starRating >= 1 ? styles.starSelected : styles.starUnselected, {color: thispost.buttonColour}]} />
					          </TouchableOpacity>
					          <TouchableOpacity onPress={() => {setStarRating(3);setRating(3);storedRating(3);}} disabled={hasRated == null ? false : true}>
					          	<MaterialIcons name={starRating >= 3 ? 'star' : 'star-border'} size={50} style={[starRating >= 1 ? styles.starSelected : styles.starUnselected, {color: thispost.buttonColour}]} />
					          </TouchableOpacity>
					          <TouchableOpacity onPress={() => {setStarRating(4);setRating(4);storedRating(4);}} disabled={hasRated == null ? false : true}>
					          	<MaterialIcons name={starRating >= 4 ? 'star' : 'star-border'} size={50} style={[starRating >= 1 ? styles.starSelected : styles.starUnselected, {color: thispost.buttonColour}]} />
					          </TouchableOpacity>
					          <TouchableOpacity onPress={() => {setStarRating(5);setRating(5);storedRating(5);}} disabled={hasRated == null ? false : true}>
					          	<MaterialIcons name={starRating >= 5 ? 'star' : 'star-border'} size={50} style={[starRating >= 1 ? styles.starSelected : styles.starUnselected, {color: thispost.buttonColour}]} />
					          </TouchableOpacity>
									</View>
									<Text style={styles.body}>Rate this product from 1 - 5 stars, with 1 being poor & 5 being excellent.</Text>
									<Text style={[styles.bodybold, {fontSize: 11}]}>Current rating: {average_rating == 0 ? "no ratings yet" : average_rating}</Text>
							</View>

							<Pressable style={[styles.downloadbutton, {backgroundColor: thispost.buttonColour, marginVertical:25}]} onPress={() => {props.navigation.navigate('productdetails', {screen: 'ProductDetailsScreen',params: {"id":thispost.nextProductId}}); scrollViewRef.current.scrollTo({ y: 0, animated: false });}}>
								<Text style={styles.downloadbuttontext}>Next product</Text><MaterialIcons name={'arrow-forward-ios'} size={18} style={{color: "#fff",marginLeft: 5}}/>
        			</Pressable>

						</View>
					</View>
				</ScrollView>
				);
			}
		}
	}
}

const screenwidth = Dimensions.get("window").width;
const whatsinthekitGap = Math.floor(screenwidth / 100 * 5) - 2;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    marginBottom: 40,
    top: 0,
  },
  detailswrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
  },
  headerwrapper: {
  	flex: 1,
    justifyContent: 'space-between',
    flexDirection: "row",
    backgroundColor: "transparent",
    alignItems: "center",
    marginTop: Platform.OS == "ios" ? 9 : 14,
    ...Platform.select({
      ios: {
        height: 74
      }
    }),
  },
  headerlogo: {
  	width: 150,
  	height: 36,
  	marginLeft: 21,
  },
  title: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 15,
  },
  body: {
    fontSize: 13,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    lineHeight: 17,
  },
  productsummary: {
  	fontSize: 15,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 16,
  },
  heading: {
    fontSize: 15,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
    width: "100%",
  },
  imagewrapper: {
  	flex: 1,
  	marginTop: 30,
  	alignItems: "center",
  	backgroundColor: "transparent",
  	marginBottom: 5,
  },
  productimage: {
    height: 320,
    width: 200,
  },
  downloadbutton: {
  	alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 13,
    paddingHorizontal: 5,
    marginBottom: 14,
    flexDirection: "row",
  },
  downloadbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
    marginRight: 10,
  },
  faqsbutton: {
  	alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 11,
    paddingHorizontal: 5,
    marginBottom: 14,
    borderWidth: 1,
  },
  featureswrapper: {
  	marginTop: 15,
    backgroundColor: "transparent",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  featurewrapper: {
  	backgroundColor: "transparent",
    width: "49%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 20,
  },
  featureproductimagewrapper: {
  	paddingRight: 8,
  	backgroundColor: "transparent",
  },
  whatsinthekit: {
  	flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  whatsinthekitwrapper: {
  	flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 10,
    columnGap: Platform.OS == "web" ? "5%" : whatsinthekitGap,
    backgroundColor: "transparent",
  },
  kitwrapper: {
    width: "30%",
    backgroundColor: "transparent",
    marginBottom: 16,
  },
  kitimagewrapper: {
  	backgroundColor: "transparent",
  	marginBottom: 3,
  	aspectRatio: 1,
  },
  kitimgbox: {
  	width: "100%",
  	height: "100%",
  	borderWidth: 1,
  	borderColor: "#ddd",
  	flex: 1,
  	justifyContent: "center",
  	alignItems: "center",
  },
  kitimage: {
  	resizeMode: "cover",
  	width: "70%",
  	height: "70%",
  },
  kittext: {
    fontSize: 13,
    fontFamily: 'GalanoGrotesqueBold',
    lineHeight: 15,
  },
  howtouse: {
  	flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    marginBottom: 0,
  },
  interpreting: {
  	flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    marginBottom: 25,
  },
  nextstepswrapper: {
  	backgroundColor: "transparent",
    marginBottom: 25,
  },
  accordionheader: {
  	backgroundColor: "#000",
  	paddingVertical: 25,
    paddingHorizontal: 20,
    marginBottom: 14,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  accordionheadertext: {
  	fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
  },
  starratingwrapper: {
  	backgroundColor: "transparent",
    marginTop: 10,
    marginBottom: 10,
  },
  starwrapper: {
  	flexDirection: "row",
  	justifyContent: "space-between",
  	backgroundColor: "transparent",
  	marginBottom: 12,
  },
});
