import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, Pressable, Linking } from 'react-native';
import React from 'react';  
import { Text, View } from "../components/Themed";
import { useNavigation, useRoute } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';

export default function Article2Screen (props) {  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.usefulwrapper}>
        <Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginVertical: 20, flexDirection: "row" }}>
            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: "#000", height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 4}]}>Back to supporting articles</Text>
        </Pressable>

        <Text style={styles.maintitle}>Understanding sperm count and what you can do about it</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
        
        <Text style={styles.bodybold}>If you are planning a family, or have been trying to conceive for some time without success, you might be wondering what part sperm count plays in getting pregnant and if it really matters?</Text>
        
        <Text style={styles.body}>"The fact that sperm counts have fallen across a short timescale of 50-70 years suggests that the causes must be lifestyle and environmental, rather than genetic. This also means that the decline is probably preventable, and possibly reversible."{"\n"}
        <Text style={{fontFamily: 'GalanoGrotesqueItalic',}}>Sharpe RM. Sperm counts and fertility in men: a rocky road ahead. Science & Society Series on Sex and Science. EMRO Rep. 2012 May 1</Text></Text>

        <Text style={styles.body}>Well it certainly matters, but it’s not the whole picture. Overall semen health is the goal, which is subtly different.</Text>

        <Text style={styles.body}>‘Sperm count’ is a measure of the average number of sperm cells in semen from a single ejaculation. <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3343360/')}>Studies have shown</Text> that male sperm count has been steadily declining since the 1930s-1940s. The proportion of men with a sperm count less than 40 million per millilitre (ml) increased from around 15% in the 1930s to around 40% by the 1990s–2000, and then <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.goldjournal.net/article/S0090-4295(19)30642-9/fulltext')}>further still by 10%</Text> over following 16 years. Doctors call low sperm count oligospermia or oligozoospermia.</Text>

        <Text style={styles.body}>A full semen analysis assessing sperm quality, or fitness, as well as quantity can be conducted by your doctor or medical professional, but, an over the counter (OTC) self-diagnostic sperm count test kit can help you quickly and affordably begin to understand this particular fertility marker from the comfort of your own home.</Text>

        <Text style={styles.body}>It takes just one sperm to fertilise a woman's egg, but the odds of that one succeeding are really quite low. A typical fertile man produces just <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3343360/')}>5-15% morphologically normal sperm cells</Text> - strong specimens with an optimal shape and physical fitness to stay the course and penetrate the egg. Most sperms tire and perish enroute because they are not fit; the journey from vagina to fallopian tube (where fertilisation takes place) is long and winding and the egg’s outer wall is tough to pierce.</Text>

        <Text style={styles.body}>Nature’s way of increasing these odds is to produce and release sperm by the millions, the magic number for which is 15 million per millilitre of semen or, 39 million in total per ejaculate. This is the clinically agreed minimum quantity of sperm offering reasonable chances of a couple conceiving naturally. When a man’s sperm count is consistently below this threshold, the chances of pregnancy occurring natural are low, although not impossible.</Text>

        <Text style={styles.body}>As sperm counts increase from zero to 40 million/ml there is a relative gradual increase in the odds of pregnancy, but interestingly beyond 40 million/ml there is no added advantage. A particularly high sperm count, therefore, does not bring additional benefits to fertility, whereas sperm quality does.</Text>

        <Text style={styles.body}>Factors such as motility (ability to swim), velocity (strength and speed) and morphology (shape) combine to determine semen fitness. A strong vigorous and determined swimming style, oval spearhead shape and long tail for propulsion sets things up for success. When you produce a higher proportion of sperm displaying winning characteristics, fertility moves in your favour.</Text>

        <Text style={styles.body}>It’s wise, therefore, to know how to establish favourable conditions for fostering optimal sperm counts:</Text>

        <Text style={styles.body}><Text style={styles.bodybold}>• Manage supply and demand:</Text> Sperm cell generation is called spermatogenesis. Sperms are made daily in the testes and incubated for about 10 weeks (74 days) until they die and get reabsorbed by the body. Frequent ejaculations deplete average numbers.</Text>

        <Text style={styles.body}><Text style={styles.bodybold}>• Keep it ambient:</Text> <Text style={styles.bodylink} onPress={() => Linking.openURL('https://academic.oup.com/humrep/article/30/5/1006/591060')}>elevated scrotal temperature</Text> is dramatically stressful to sperm; it can prevent healthy spermatogenesis and cause live sperm to die-off early (apoptosis). Avoid tight clothing and clothing made of synthetic materials; get up and moving around frequently, especially if your job requires you to sit for long periods of time; and lose any excess belly fat, particularly if your paunch prevents good air circulation when you’re sat down.</Text>

        <Text style={styles.body}><Text style={styles.bodybold}>• Watch what you put in:</Text> it impacts what comes out: <Text style={styles.bodylink} onPress={() => Linking.openURL('https://academic.oup.com/humupd/article/23/4/371/3065333')}>Scientists are now finding</Text> that diet matters and eating a diet rich in red meats, processed meats, and full-fat dairy (animal products high in saturated fats) negatively impact the chances of pregnancy occurring due to low quality semen. High intakes of caffeine and sugar-sweetened beverages (or just sweets, generally) do the same.</Text>

        <Text style={styles.body}><Text style={styles.bodybold}>• Give up the unnecessary:</Text> If cigarette smoking, recreational drugs or a regular high intake of alcohol is your thing, understand once and for all that these are all toxic substances to the human body and are received as poisons. Inhaling, injecting, or ingesting them is self-destructive and sits contrary to the sentiment of wanting to make a baby, so, now is the time to just stop.</Text>

        <Text style={styles.body}>Bear in mind, the number of sperm cells your testes are capable of making was determined many moons ago when you were a mere foetus in your mother’s womb, and it is fixed. Indeed, her own lifestyle factors would have influenced this potential by around 20-40%. But while you are unable to increase the number you generate, your adult lifestyle choices are most definitely within your gift to shape to ensure you have as many winning specimens at your disposal as possible.</Text>

        <Text style={styles.body}>2San’s full range of over the counter self-diagnostic tests, including sperm count, can be found on our <Text style={styles.bodylink} onPress={() => Linking.openURL('https://2san.com/self-diagnostics')}>website</Text>.</Text>

        <Text style={styles.bodybold}>References</Text>

        <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3343360/')}>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3343360/</Text>

        <Text style={styles.bodylink} onPress={() => Linking.openURL('https://academic.oup.com/humrep/article/30/5/1006/591060')}>https://academic.oup.com/humrep/article/30/5/1006/591060</Text>

        <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.goldjournal.net/article/S0090-4295(19)30642-9/fulltext')}>https://www.goldjournal.net/article/S0090-4295(19)30642-9/fulltext</Text>

        <Text style={styles.body}>Sharpe RM. Sperm counts and fertility in men: a rocky road ahead. Science & Society Series on Sex and Science. EMBO Rep. 2012 May 1;13(5):398-403. doi: 10.1038/embor.2012.50. PMID: 22491033; PMCID: PMC3343360.</Text>

        <Text style={styles.bodylink} onPress={() => Linking.openURL('https://academic.oup.com/humupd/article/23/4/371/3065333')}>https://academic.oup.com/humupd/article/23/4/371/3065333</Text>

        <Text style={styles.body}>Ferramosca A, Zara V. Diet and Male Fertility: The Impact of Nutrients and Antioxidants on Sperm Energetic Metabolism. Int J Mol Sci. 2022 Feb 25;23(5):2542. doi: 10.3390/ijms23052542. PMID: 35269682; PMCID: PMC8910394.</Text>
        
        <Pressable style={styles.backbutton} onPress={() => props.navigation.goBack()}>
          <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff",marginRight: 5}}/><Text style={styles.backbuttontext}>Back to support articles</Text>
        </Pressable>           
        
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 20,
    marginBottom: 40,
    height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%",
  },
  usefulwrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
  },
  maintitle: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  title: {
    fontSize: 25,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  body: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
  },
  bodylink: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
    textDecorationLine: 'underline',
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 13,
    paddingHorizontal: 50,
    marginBottom: 25,
    marginTop: 15,
    backgroundColor: "#000",
    width: "100%",
    flexDirection: "row",
  },
  backbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
  },
});