import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, Pressable, Linking } from 'react-native';
import React from 'react';  
import { Text, View } from "../components/Themed";
import { useNavigation, useRoute } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';

export default function Article4Screen (props) {  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.usefulwrapper}>
        <Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginVertical: 20, flexDirection: "row" }}>
            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: "#000", height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 4}]}>Back to supporting articles</Text>
        </Pressable>

        <Text style={styles.maintitle}>Tips for trying to conceive</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
        
        <Text style={styles.bodybold}>Are you and your partner trying to increase your chances of getting pregnant? It’s important to understand your fertile window. Understanding when you're most fertile can increase the likelihood of successful conception. If you’re trying to conceive, we recommend using the 2San Peak Fertility Test to find your two most fertile days of the month to give you the best chance at conception.</Text>
        
        <Text style={styles.body}>It’s important to remember that, although some women get pregnant very quickly, it’s also perfectly natural for it to take up to a year for a couple under 40 and in good health to conceive. Many factors can affect a couple's chances of conceiving, such as: your age, your general health, your reproductive health, and how often you have sex.</Text>

        <Text style={styles.bodybold}>Understanding Fertility and Ovulation</Text>

        <Text style={styles.body}>Did you know there are a few days a month when you have the best chance of conceiving, which is around the time the ovaries release an egg?</Text>

        <Text style={styles.body}>You’re more likely to get pregnant around the time you are ovulating. This is when an egg is released, and you are at your most fertile. The best time to try for a baby is on the days leading up to ovulation and the day of ovulation itself.</Text>

        <Text style={styles.body}>The <Text style={styles.bodybold}>2San Peak Fertility Test</Text> is designed to help increase the chances of conception by measuring levels of an important fertility hormone called Luteinising Hormone (LH). This simple urine test, similar to a pregnancy test, provides quick results in just 3 minutes, making it an easy and effective tool for tracking “peak” fertility. LH levels typically rise 24-36 hours before ovulation, indicating the best time for conception.</Text>

        <Text style={styles.body}>If you are under 40 and have regular sex without using contraception, there is an 8 in 10 chance you will get pregnant within 1 year. Having regular sex means having sex every 2 to 3 days throughout the month.</Text>

        <Text style={styles.body}>To maximise your chances of conception, here are several steps you and your partner can take:</Text>

        <View style={{flexDirection: 'row'}}>
          <Text>{'\u2022'}</Text>
          <Text style={[styles.body, {flex: 1, paddingLeft: 5, marginBottom: 5}]}><Text style={styles.bodybold}>Have regular intercourse:</Text> Engage in sexual activity every 2 to 3 days throughout the month to maximise the probability of conception.</Text>
        </View>

        <View style={{flexDirection: 'row'}}>
          <Text>{'\u2022'}</Text>
          <Text style={[styles.body, {flex: 1, paddingLeft: 5, marginBottom: 5}]}><Text style={styles.bodybold}>Time intercourse with ovulation:</Text> To optimise your chances further, try to have intercourse around the time of ovulation, which usually occurs 12 to 16 days before your period starts.</Text>
        </View>

        <View style={{flexDirection: 'row'}}>
          <Text>{'\u2022'}</Text>
          <Text style={[styles.body, {flex: 1, paddingLeft: 5}]}><Text style={styles.bodybold}>Maintain a healthy lifestyle:</Text> Both partners should try to maintain a healthy weight, reduce or cut down on alcohol, and refrain from smoking. These lifestyle choices can positively impact fertility.</Text>
        </View>

        <Text style={styles.body}>It's important to remember that conception timelines can vary for couples. If you’ve been actively trying to conceive for a year without success, it’s advisable to consult your GP for guidance and advice.</Text>

        <Text style={styles.body}><Text style={{fontSize: 12}}>Sources:</Text></Text>

        <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.nhs.uk/pregnancy/trying-for-a-baby/how-long-it-takes-to-get-pregnant/')}><Text style={{fontSize: 12}}>https://www.nhs.uk/pregnancy/trying-for-a-baby/how-long-it-takes-to-get-pregnant/</Text></Text>

        <Text style={styles.bodylink} onPress={() => Linking.openURL('https://www.tommys.org/pregnancy-information/planning-a-pregnancy/how-to-get-pregnant/how-long-does-it-take-get-pregnant')}><Text style={{fontSize: 12}}>https://www.tommys.org/pregnancy-information/planning-a-pregnancy/how-to-get-pregnant/how-long-does-it-take-get-pregnant</Text></Text>        

        <Pressable style={styles.backbutton} onPress={() => props.navigation.goBack()}>
          <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff",marginRight: 5}}/><Text style={styles.backbuttontext}>Back to support articles</Text>
        </Pressable>         
        
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 20,
    marginBottom: 40,
    height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%",
  },
  usefulwrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
  },
  maintitle: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  title: {
    fontSize: 25,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  body: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
  },
  bodylink: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
    textDecorationLine: 'underline',
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 13,
    paddingHorizontal: 50,
    marginBottom: 25,
    marginTop: 15,
    backgroundColor: "#000",
    width: "100%",
    flexDirection: "row",
  },
  backbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
  },
});