import { Platform, StyleSheet, Button, SafeAreaView, ScrollView, Dimensions, Image, Pressable, Linking } from 'react-native';
import React from 'react';  
import { Text, View } from "../components/Themed";
import { useNavigation, useRoute } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';

export default function UsefulLinksScreen (props) {  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.usefulwrapper}>
        <Pressable onPress={() => props.navigation.goBack()} style={{width: "100%", marginVertical: 20, flexDirection: "row" }}>
            <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff", backgroundColor: "#000", height: 26, width: 26, paddingLeft: 8, marginRight: 10, paddingTop: 4}}/><Text style={[styles.bodybold, {paddingTop: 4}]}>Back to menu</Text>
        </Pressable>

        <Text style={styles.maintitle}>Useful Links</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
        
        <Text style={styles.body}>Below are some useful links related to our product range, the link relating to the product you are looking at will help to give you a better understanding of what you are testing for.</Text>
        
        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>

        <Text style={styles.title}>Health & Wellness</Text>

        <Text style={[styles.prodheading, {color: "#3B8046"}]}>Iron deficiency</Text>
        <Text style={[styles.bodylink, {color: "#3B8046"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/iron-deficiency-anaemia/')}>Iron deficiency anaemia (www.nhs.uk)</Text>

        <Text style={[styles.prodheading, {color: "#3B8046"}]}>Vitamin D</Text>
        <Text style={[styles.bodylink, {color: "#3B8046"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/vitamins-and-minerals/vitamin-d/')}>Vitamin D deficiency (www.nhs.uk)</Text>

        <Text style={[styles.prodheading, {color: "#3B8046"}]}>Hypothyroidism</Text>
        <Text style={[styles.bodylink, {color: "#3B8046"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/underactive-thyroid-hypothyroidism/')}>Underactive thyroid (www.nhs.uk)</Text>

        <Text style={[styles.prodheading, {color: "#3B8046"}]}>Bowel cancer</Text>
        <Text style={[styles.bodylink, {color: "#3B8046"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/bowel-cancer/symptoms/')}>Symptoms of bowel cancer (www.nhs.uk)</Text>

        <Text style={[styles.prodheading, {color: "#3B8046"}]}>Stomach ulcer</Text>
        <Text style={[styles.bodylink, {color: "#3B8046"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/stomach-ulcer/causes/')}>Stomach Ulcer (www.nhs.uk)</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
 
        <Text style={styles.title}>Drugs of Abuse</Text>

        <Text style={[styles.prodheading, {color: "#2F6B9D"}]}>Drugs of Abuse</Text>
        <Text style={[styles.bodylink, {color: "#2F6B9D"}]} onPress={() => Linking.openURL('https://www.talktofrank.com/')}>FRANK (talktofrank.com)</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
 
        <Text style={styles.title}>Fertility</Text>

        <Text style={[styles.prodheading, {color: "#300470"}]}>Male fertility</Text>
        <Text style={[styles.bodylink, {color: "#300470"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/low-sperm-count/')}>Low sperm count (www.nhs.uk)</Text>

        <Text style={[styles.prodheading, {color: "#300470"}]}>Peak fertility</Text>
        <Text style={[styles.bodylink, {color: "#300470"}]} onPress={() => Linking.openURL('https://www.nhs.uk/pregnancy/trying-for-a-baby/')}>Trying for a baby (www.nhs.uk)</Text>

        <Text style={[styles.prodheading, {color: "#300470"}]}>Early pregnancy</Text>
        <Text style={[styles.bodylink, {color: "#300470"}]} onPress={() => Linking.openURL('https://www.nhs.uk/pregnancy/')}>Pregnancy (www.nhs.uk)</Text>

        <View style={{height: 1, backgroundColor: '#ddd',width: "100%",marginTop:17,marginBottom:27}}></View>
 
        <Text style={styles.title}>Infection</Text>

        <Text style={[styles.prodheading, {color: "#DE5929"}]}>Urinary tract infections</Text>
        <Text style={[styles.bodylink, {color: "#DE5929"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/urinary-tract-infections-utis/')}>UTI (www.nhs.uk)</Text>

        <Text style={[styles.prodheading, {color: "#DE5929"}]}>Bacterial vaginosis</Text>
        <Text style={[styles.bodylink, {color: "#DE5929"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/bacterial-vaginosis/')}>BV (www.nhs.uk)</Text>

        <Text style={[styles.prodheading, {color: "#DE5929"}]}>Covid & Flu</Text>
        <Text style={[styles.bodylink, {color: "#DE5929"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/covid-19/')}>COVID-19 (www.nhs.uk)</Text>
        <Text style={[styles.bodylink, {color: "#DE5929"}]} onPress={() => Linking.openURL('https://www.nhs.uk/conditions/flu/')}>Flu (www.nhs.uk)</Text>

        <Pressable style={styles.backbutton} onPress={() => props.navigation.goBack()}>
          <MaterialIcons name={'arrow-back-ios'} size={18} style={{color: "#fff",marginRight: 5}}/><Text style={styles.backbuttontext}>Back to menu</Text>
        </Pressable>        
        
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 20,
    marginBottom: 40,
    height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%",
  },
  usefulwrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
  },
  maintitle: {
    fontSize: 28,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  title: {
    fontSize: 25,
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 30,
    marginBottom: 10,
  },
  body: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
  },
  bodylink: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
    textDecorationLine: 'underline',
  },
  bodybold: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 12,
  },
  prodheading: {
    fontSize: 14,
    fontFamily: 'GalanoGrotesqueBold',
    marginBottom: 10,
    marginTop: 14,
  },
  backbutton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 13,
    paddingHorizontal: 50,
    marginBottom: 25,
    marginTop: 40,
    backgroundColor: "#000",
    width: "100%",
    flexDirection: "row",
  },
  backbuttontext: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueBold',
  },
});