import { Platform, StyleSheet, Pressable, SafeAreaView, ScrollView, Dimensions, Image, ImageBackground } from 'react-native';
import React from 'react';  

import { Text, View } from "../components/Themed";
import bgimage from "../assets/images/homescreen-background.jpg";

export default function WelcomeScreen (props) {  
  return (
    <ImageBackground source={bgimage} resizeMode="cover" style={styles.imgwrapper}> 
      <View style={styles.container}>
        <Text style={styles.title}>Welcome to the 2San Diagnostics Product Support Hub</Text>
        <Text style={styles.body}>Everything you need to know about our diagnostics products in your pocket - videos, downloads, and key product information to support our product usage.</Text>
        <Text style={styles.body}>We have also included some useful links and supporting articles. We welcome your feedback to continually improve our customers experience and support.</Text>
        <Pressable style={styles.button} onPress={() => props.navigation.navigate('productmenu')}>
          <Text style={styles.text}>View All Products</Text>
        </Pressable>
      </View>
      </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 27,
    fontFamily: 'GalanoGrotesqueBold',
    color: "#fff",
    maxWidth: 280,
    textAlign: "center",
    paddingBottom: 20,
    paddingTop: 100,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 14,
    paddingHorizontal: 50,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: "#fff",
    marginTop: 30,
  },
  body: {
    fontSize: 13,
    fontFamily: 'GalanoGrotesqueRegular',
    marginBottom: 12,
    color: "#fff",
    textAlign: "center",
    paddingHorizontal: 24,
  },
  text: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'GalanoGrotesqueRegular',
  },
  imgwrapper: {
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
  },
});
