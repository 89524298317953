import { Pressable, Platform, StyleSheet, SafeAreaView, ScrollView, Dimensions, Image } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import React from 'react';  

import { Text, View } from "../components/Themed";
import prodLinks from "../components/ProductList";
let xxx = JSON.stringify(prodLinks);


export default function ProductMenuScreen (props) {  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.productswrapper}>
	    {
    		prodLinks.map((post,i)=>{
    			if (post.id != 0)
    			{
    			  return <Pressable style={styles.button} key={i} 
    				  onPress={() => props.navigation.navigate('productdetails', {screen: 'ProductDetailsScreen',params: {"id":post.id}})}>
    				   <Image style={styles.productimage} 
    				   source={post.image} 
    				   />
    				  <Text style={styles.text}>{post.name}</Text>
    				  </Pressable>;
    			}
    		})
	    }
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 20,
    marginBottom: 40,
    height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%",
  },
  productswrapper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingHorizontal: 25,
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  button: {
    alignItems: "center",
    width: "48%",
    backgroundColor: "transparent",
    marginBottom: 20,
  },
  text: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: 'GalanoGrotesqueRegular',
    lineHeight: 14,
  },
  productimage: {
    height: 150,
    width: 94,
  },
});
