import { Platform, StyleSheet, Pressable, SafeAreaView, ScrollView, Dimensions, Image, ImageBackground, useColorScheme } from 'react-native';
import React, {useState, useEffect} from 'react';  

import { Text, View } from "../components/Themed";
import { LinearGradient } from 'expo-linear-gradient';

import prodLinks from "../components/ProductList";
import catLinks from "../components/CategoryList";

let xxx = JSON.stringify(prodLinks);

export default function ProductMenuTextScreen (props) {  
	const [stateProductId, setstateProductId] = useState(null);
	const colorScheme = useColorScheme();
  const isDarkMode = colorScheme === 'dark';
	let currcat=1;
	let nextcat=currcat + 1;
	let limit=4;
	let productBgColour = "";

  return (
  	<ScrollView style={{height: Platform.OS == "web" ? "calc(100vh - 150px)" : "100%"}}>	
	    <View style={styles.container}>

		  	<View style={styles.navwrapper}>
					{catLinks.map((postc,i)=>{
						if (postc.id == 1)
						{
							productBgColour = postc.menuBackgroundColour;
							return <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[postc.gradientBackgroundColour1, postc.gradientBackgroundColour2]} key={i}>
								<Text style={styles.textcategory}>{postc.name}</Text>
								</LinearGradient>;
						}					
					})
					}
					<View style={{paddingVertical: 8, backgroundColor: productBgColour}}>
					{prodLinks.map((post,i)=>{
						if (post.categoryid == 1)
						{
						return <Pressable style={[styles.button, {backgroundColor: productBgColour}]} key={i} onPress={() => props.navigation.navigate('productdetails', {screen: 'ProductDetailsScreen',params: {"id":post.id}})}>
							<Text style={[styles.text, {color: post.buttonColour}]}>{post.name}</Text>
						</Pressable>;
						}
					})
					}
					</View>

					{catLinks.map((postc,i)=>{
						if (postc.id == 2)
						{
							productBgColour = postc.menuBackgroundColour;
							return <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[postc.gradientBackgroundColour1, postc.gradientBackgroundColour2]} key={i}>
								<Text style={styles.textcategory}>{postc.name}</Text>
								</LinearGradient>;
						}					
					})
					}
					<View style={{paddingVertical: 8, backgroundColor: productBgColour}}>
					{prodLinks.map((post,i)=>{
						if (post.categoryid == 2)
						{
						return <Pressable style={[styles.button, {backgroundColor: productBgColour}]} key={i}	onPress={() => props.navigation.navigate('productdetails', {screen: 'ProductDetailsScreen',params: {"id":post.id}})}>
							<Text style={[styles.text, {color: post.buttonColour}]}>{post.name}</Text>
						</Pressable>;
						}
					})
					}
					</View>

					{catLinks.map((postc,i)=>{
						if (postc.id == 3)
						{
							productBgColour = postc.menuBackgroundColour;
							return <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[postc.gradientBackgroundColour1, postc.gradientBackgroundColour2]} key={i}>
								<Text style={styles.textcategory}>{postc.name}</Text>
								</LinearGradient>;
						}					
					})
					}
					<View style={{paddingVertical: 8, backgroundColor: productBgColour}}>
					{prodLinks.map((post,i)=>{
						if (post.categoryid == 3)
						{
						return <Pressable style={[styles.button, {backgroundColor: productBgColour}]} key={i}	onPress={() => props.navigation.navigate('productdetails', {screen: 'ProductDetailsScreen',params: {"id":post.id}})}>
							<Text style={[styles.text, {color: post.buttonColour}]}>{post.name}</Text>
						</Pressable>;
						}
					})
					}
					</View>

					{catLinks.map((postc,i)=>{
						if (postc.id == 4)
						{
							productBgColour = postc.menuBackgroundColour;
							return <LinearGradient start={{x: 0, y: 0}} end={{x: 1, y: 0}} colors={[postc.gradientBackgroundColour1, postc.gradientBackgroundColour2]} key={i}>
								<Text style={styles.textcategory}>{postc.name}</Text>
								</LinearGradient>;
						}					
					})
					}
					<View style={{paddingVertical: 8, backgroundColor: productBgColour}}>
					{prodLinks.map((post,i)=>{
						if (post.categoryid == 4)
						{
						return <Pressable style={[styles.button, {backgroundColor: productBgColour}]} key={i}	onPress={() => props.navigation.navigate('productdetails', {screen: 'ProductDetailsScreen',params: {"id":post.id}})}>
							<Text style={[styles.text, {color: post.buttonColour}]}>{post.name}</Text>
						</Pressable>;
						}
					})
					}
					</View>
		  		
	        <Pressable style={styles.extralinks} onPress={() => props.navigation.navigate('productmenu')}>
	          <Text style={styles.text}>All Products</Text><Image style={{height:14,width:8}} source={isDarkMode ? require("../assets/images/right-arrow-icon-white.png") : require("../assets/images/right-arrow-icon.png")} />
	        </Pressable>

	        <Pressable style={styles.extralinks} onPress={() => props.navigation.navigate('usefullinks')}>
	          <Text style={styles.text}>Useful Links</Text><Image style={{height:14,width:8}} source={isDarkMode ? require("../assets/images/right-arrow-icon-white.png") : require("../assets/images/right-arrow-icon.png")} />
	        </Pressable>

	        <Pressable style={styles.extralinks} onPress={() => props.navigation.navigate('supportingarticles')}>
	          <Text style={styles.text}>Supporting Articles</Text><Image style={{height:14,width:8}} source={isDarkMode ? require("../assets/images/right-arrow-icon-white.png") : require("../assets/images/right-arrow-icon.png")} />
	        </Pressable>

	        <Pressable style={styles.extralinks} onPress={() => props.navigation.navigate('contactsupport')}>
	          <Text style={styles.text}>Support & Feedback</Text><Image style={{height:14,width:8}} source={isDarkMode ? require("../assets/images/right-arrow-icon-white.png") : require("../assets/images/right-arrow-icon.png")} />
	        </Pressable>
		  	</View>

	    </View>
	  </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    top: 10,
    marginBottom: 35,
  },
  navwrapper: {
  	backgroundColor: "transparent",
  	width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  button: {
  	alignItems: "center",
  	paddingVertical: 7,
  	flexDirection: "row",
  },
  text: {
  	marginLeft: 38,
  	marginRight: 20,
  	fontFamily: 'GalanoGrotesqueRegular',
  	fontSize: 15,
  },
  textcategory: {
  	marginLeft: 38,
  	marginRight: 20,
  	fontFamily: 'GalanoGrotesqueBold',
  	fontSize: 16,
  	alignItems: "center",
  	paddingVertical: 13,
  	color: "#fff",
  },
  extralinks: {
  	justifyContent: "space-between",
  	flex: 1,
  	flexDirection: "row",
  	marginRight: 30,
  	paddingTop: 20,
  	alignItems: "center",
  },
});
