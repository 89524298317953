import "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { useLoadedAssets } from "./hooks/useLoadedAssets";
import { Platform, useColorScheme, Image, Pressable } from "react-native";
import { useFonts } from 'expo-font';

import Hamburger from "./components/Hamburger";

import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import WelcomeScreen from './screens/Welcome.js';
import ProductMenuScreen from './screens/ProductMenu.js';
import ProductMenuTextScreen from './screens/ProductMenuText.js';
import ProductDetailsScreen from './screens/ProductDetails.js';
import ContactSupportScreen from './screens/ContactSupport.js';
import ProductFaqsScreen from './screens/ProductFaqs.js';
import UsefulLinksScreen from './screens/UsefulLinks.js';
import SupportingArticlesScreen from './screens/SupportingArticles.js';
import Article1Screen from './screens/Article1.js';
import Article2Screen from './screens/Article2.js';
import Article3Screen from './screens/Article3.js';
import Article4Screen from './screens/Article4.js';

function LogoBlack() {
  return (
    <Image
      style={{ width: 150, height: 36, marginLeft: 5 }}
      source={require("./assets/images/2san-logo-black.png")}
    />
  );
}
function LogoWhite() {
  return (
    <Image
      style={{ width: 150, height: 36, marginLeft: 5 }}
      source={require("./assets/images/2san-logo-white.png")}
    />
  );
}

const Stack = createStackNavigator();

function App({ colorScheme }) {
  const [loaded] = useFonts({
    GalanoGrotesqueRegular: require('./assets/fonts/GalanoGrotesque-Regular.otf'),
    GalanoGrotesqueBold: require('./assets/fonts/GalanoGrotesque-Bold.otf'),
    GalanoGrotesqueItalic: require('./assets/fonts/GalanoGrotesque-Italic.otf'),
  });

  const isLoadingComplete = useLoadedAssets();

  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: '#fff',
    },
  };

  if (!isLoadingComplete && !loaded) {
    return null;
  } else {
    return (
      <NavigationContainer theme={colorScheme === "dark" ? MyTheme : MyTheme}>
        <Stack.Navigator screenOptions={{
          gestureDirection: 'horizontal',
          gestureEnabled: true,
        }}>
          <Stack.Screen
            name="welcome"
            component={WelcomeScreen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerTransparent: true,
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "transparent",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
              ),
            title: "Welcome | 2San Diagnostics",
            })}
          />
        
          <Stack.Screen
            name="productmenu"
            component={ProductMenuScreen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
            ),
            title: "Products | 2San Diagnostics",
            })}
          />
        
          <Stack.Screen
            name="productmenutext"
            component={ProductMenuTextScreen}
            options={({ navigation }) => ({
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('welcome')}>
                  <Image style={{ width: 24, height: 24, right: 27, top: -2, paddingLeft: 4 }} source={require("./assets/images/close-icon.png")} />
              </Pressable>
            ),
            title: "Products | 2San Diagnostics",
            })}
          />
          
          <Stack.Screen
            name="productdetails"
            component={ProductDetailsScreen}
            options={({ navigation }) => ({
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerShown: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => {
              return null
            },
            title: "Product Details | 2San Diagnostics",
            })}
          />

          <Stack.Screen
            name="contactsupport"
            component={ContactSupportScreen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
            ),
            title: "Contact Support | 2San Diagnostics",
            })}
          />

          <Stack.Screen
            name="usefullinks"
            component={UsefulLinksScreen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
            ),
            title: "Useful Links | 2San Diagnostics",
            })}
          />

          <Stack.Screen
            name="productfaqs"
            component={ProductFaqsScreen}
            options={({ navigation }) => ({
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerShown: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => {
              return null
            },
            title: "Product Faq's | 2San Diagnostics",
            })}
          />

          <Stack.Screen
            name="supportingarticles"
            component={SupportingArticlesScreen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
            ),
            title: "Supporting Articles | 2San Diagnostics",
            })}
          />

          <Stack.Screen
            name="article1"
            component={Article1Screen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
            ),
            title: "Why rapid testing can help children get back to school safely | 2San Diagnostics",
            })}
          />

          <Stack.Screen
            name="article2"
            component={Article2Screen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
            ),
            title: "Understanding sperm count and what you can do about it | 2San Diagnostics",
            })}
          />

          <Stack.Screen
            name="article3"
            component={Article3Screen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
            ),
            title: "Understanding sperm count and what you can do about it | 2San Diagnostics",
            })}
          />

          <Stack.Screen
            name="article4"
            component={Article4Screen}
            options={({ navigation }) => ({ 
            headerTitle: (props) => (<LogoBlack {...props} />),
            headerTitleAlign: "left",
            headerStyle: {
              shadowColor: "transparent",
              shadowOpacity: 0,
              borderBottomWidth: 0,
              backgroundColor: "#fff",
              ...Platform.select({
                ios: {
                  height: 90,
                },
              }),
            },
            headerBackTitleVisible: false,
            headerLeft: () => {
              return null
            },
            headerRight: () => (
              <Pressable onPress={() => navigation.navigate('productmenutext')}>
                  <Hamburger />
              </Pressable>
            ),
            title: "Understanding sperm count and what you can do about it | 2San Diagnostics",
            })}
          />
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}

export default App;